<template>
	<div data-view>
		<Banner />
        <OtherLocations :related-queries="relatedQueries" />
		<Featured />
		<Benefits />
        <Why />
        <Blog />
        <ContactForm />
    </div>
</template>

<script>
import Banner from "@/new-components/Banner/Banner.vue";
import Featured from "@/components/Featured/Featured";
import Why from "@/components/Why/Why";
import Blog from "@/components/Blog/Blog";
import Benefits from "@/components/Benefits/Benefits";
import RelatedSearchQuery from "@/components/SearchQuery/RelatedSearchQuery.vue";
import {getOthersCitiesLinks} from "@/Utils/seo";
import OtherLocations from "@/components/SearchQuery/OtherLocations.vue";
import ContactForm from "@/components/ContactForm/ContactForm.vue";

export default {
	components: {
        ContactForm,
        OtherLocations,
        RelatedSearchQuery,
		Banner,
		Featured,
        Why,
        Blog,
		Benefits,
	},
    data() {
        return {
            relatedQueries: null,
        };
    },
	created() {
        this.relatedQueries = getOthersCitiesLinks();
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}, 500);
		window.addEventListener("scroll", () => {
			const altura = window.innerHeight - window.pageYOffset;
			if (altura < 1) {
				this.$store.commit("setLoadLocation", true);
			}
		});
	}
};
</script>
