<template>
    <div class="mobile-items mobile-only">
        
        <!-- Logo -->
        <div class="mobile-logo">
            <router-link :to="'/'">
                <img :src="assets.logoHorizontalSrc" alt="Logo"/>
            </router-link>
        </div>

        <!-- Sidebar Open/Clos button -->
        <OpenCloseButton :isOpen="isNavOpen" @toggle="toggleNav" />
        
        <!-- Sidebar -->
        <Sidebar :isOpen="isNavOpen" @close="closeNav" class="sidebar"/>
        
    </div>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import OpenCloseButton from "@/components/Header/OpenCloseButton.vue";
import { assets } from "@/new-constants/assets";

export default {
    name: 'HeaderMobile',
    components: {
        OpenCloseButton,
        Sidebar
    },
    data() {
        return {
            isNavOpen: false,
            assets
        };
    },
    methods: {
        toggleNav() {
            // Toggle menu with the open/close button
            this.isNavOpen = !this.isNavOpen;
        },
        closeNav() {
            this.isNavOpen = false;
        }
    }
}
</script>

<style lang="scss" scoped>

.mobile-items {
    align-items: center;
    z-index: 101;

    .mobile-logo {
        img {
            width: 50%;
        }
    }
}

</style>