export default [
    {
        path: "/conversation",
        name: "conversation",
        component: () => import('@/pages/Conversation/Conversation.vue'),
        meta: {
            requiresAuth: true,
            hideBot: true
        }
    },
    {
        path: "/admin/conversations",
        name: "admin-conversations",
        component: () => import('@/pages/Conversation/AdminConversations.vue'),
        meta: {
            requiresAuth: true,
            hideBot: true
        }
    },

]