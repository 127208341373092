const getters = {
    simulation: state => state.simulation,
    accommodationId: state => state.accommodationId,
    initDate: state => state.initDate,
    endDate: state => state.endDate,
    totalDays: state => state.totalDays,
    guests: state => state.guests,
    available: state=> state.available,
};

export default getters;