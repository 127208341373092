<template>
    <div class="container">
        <Steps :size="9" :active="9" />

        <div class="circular-portrait-check">
            <i class="fas fa-check" />
        </div>
        <h1 class="mt-5 text-center">{{$t('wizard.success.title')}}</h1>
        <div v-if="update" class="text-center mt-4">{{$t('wizard.success.updated-message')}}</div>
        <div v-else class="text-center mt-4">{{$t('wizard.success.created-message')}}</div>
    </div>
</template>

<script>
import router from "@/router";
import Steps from "@/components/Wizard/Steps";

export default {
    name: "Success",

    props: ["update"],

    components: {
        Steps
    },

    created() {
        window.scrollTo(0, 0);
        setTimeout(function() {
            router.push({ name: "my-accommodations" });
        }, 5000);
    }
};
</script>

<style scoped lang="scss">
.container {
    .text-center {
        margin: auto;
        max-width: 600px;
    }

    .circular-portrait-check {
        background-color: $hf-sec-pine;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin: auto;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 100px;
        color: white;
    }
}
</style>

