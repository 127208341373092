<template>
    <div class="container-website-popup" v-show="popup">
        <div class="popup">
            <div class="title">
                Global Real Estate Marketplace
            </div>
            <div class="text">
                {{ $t("popups.websites.text") }}
            </div>
            <div class="controls">
                <a @click="closePopUp">
                    <button class="button button-purple">
                        {{ $t("popups.websites.renting") }}
                    </button>
                </a>
                <a
                    href="https://houseandflats-investments.com/"
                    v-if="$i18n.locale === 'es'"
                >
                    <button class="button button-green">
                        {{ $t("popups.websites.investments") }}
                    </button>
                </a>
                <a href="https://houseandflats-investments.com/en/home/" v-else>
                    <button class="button button-green">
                        {{ $t("popups.websites.investments") }}
                    </button>
                </a>
            </div>
            <div class="checkbox-option">
                <input
                    type="checkbox"
                    @click="togglePopup"
                    id="popupcheckbox"
                />
                <label for="popupcheckbox">{{
                    $t("popups.websites.hide-text")
                }}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            popup: false
        };
    },
    methods: {
        closePopUp(e) {
            e.preventDefault();
            this.popup = false;
        },
        togglePopup(e) {
            if (e.target.checked) {
                localStorage.setItem("popup_category", true);
            } else {
                localStorage.setItem("popup_category", false);
            }
        }
    },
    mounted() {
        if (this.$route.path === "/") {
            const popupCategory = localStorage.getItem("popup_category");
            if (!popupCategory || popupCategory === "false") {
                this.popup = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.container-website-popup {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100vh;
    z-index: 9999;
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    align-items: center;

    .popup {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        max-width: 90%;
        width: 1000px;
        aspect-ratio: 2/1;
        border-radius: 20px;
        text-align: center;
        background-image: url("../../assets/popupbg.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        @media (max-width: 600px) {
            padding: 45px 15px;
            background-position: -70px 100px;
        }

        .title {
            font-weight: bold;
            font-size: 2.5rem;
            text-shadow: 2px 2px #fff;

            @media (max-width: 600px) {
                font-size: 1.3rem;
            }
        }

        .text {
            font-size: 1.6rem;
            text-shadow: 2px 2px #fff;

            @media (max-width: 600px) {
                font-size: 1.1rem;
            }
        }

        .checkbox-option {
            width: 100%;
            text-align: left;
            bottom: 10px;
            padding: 0px 30px;
            position: absolute;
            align-items: center;
            display: flex;
            input {
                margin-right: 10px;
            }
            label {
                margin: 0;
                padding: 0;
                font-size: 0.9rem;
                color: #3e3e3e;

                @media (max-width: 600px) {
                    font-size: 0.8rem;
                }
            }
        }
    }

    .controls {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;

        a {
            width: 40%;
            margin: 0 10px;
            .button {
                appearance: none;
                border: none;
                border-radius: 10px;
                padding: 11px 0px;
                color: #fff;
                width: 100%;
                font-weight: bold;
                box-shadow: 3px 3px 5px rgb(202, 202, 202);
            }
        }
        .button.button-green {
            background-color: $hf-sec-pine;
        }

        .button.button-purple {
            background-color: $hf-sec-pine;
        }

        @media (max-width: 600px) {
            flex-direction: column;
            width: 90%;
            a {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}
</style>
