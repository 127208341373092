<template>
    <div class="full-width-bg">
        <div class="container">
            <div class="featured-title">{{$t('featured.featured-accommodations')}}</div>

            <Owl
                class="owl"
                v-if="accomodations.length"
                :responsive="{0:{items:1},600:{items:2},1000:{items:4}}"
                :autoplay="true"
                :autoplayTimeout="10000"
                :nav="false"
                ref="owl"
            >
                <div
                    :key="accomodation.idalojamiento"
                    v-for="accomodation in accomodations"
                    class="item-inner"
                >
                    <AccommodationItem
                        :accommodation="accomodation"
                    />
                </div>
            </Owl>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import Owl from "vue-owl-carousel";
import AccommodationItem from "@/components/AccommodationItem/AccommodationItem";

export default {
    name: "Featured",
    components: {
        Owl,
        AccommodationItem
    },
    methods: {
        ...mapActions(["getAccomodationFeatured"])
    },
    computed: {
        ...mapState(["accomodations"])
    },
    created() {
        this.getAccomodationFeatured().then((response) => {
            this.$store.commit('setAccomodations', response.data.accomodations);
        })
    },
};
</script>

<style lang="scss">

.owl-stage {
    display: flex !important;
}

.owl-item {
    display: flex !important;
    flex: 1 !important;
}

.item-inner {
    width: 100%;
}

</style>

<style scoped lang="scss">
@import "@/new-assets/styles";

.full-width-bg {
    width: 100%;
    background-color: $grey-backgroud;
}


.container {
    background-color: $grey-backgroud;
    padding: 50px 0;
}

.featured-title {
    padding: 20px;
    font-size: 2.3rem;
    color: $h2;
}

@media (max-width: $md) {
    .container {
        padding: 20px 0;
    }

    .featured-title {
        padding: 20px 0 0 20px;
        font-size: 1.5rem;
    }
}
</style>