<template>
    <div>

        <!-- Initial Buttons -->
        <div v-if="!isLoggedIn">
            <Button class="new-btn reserve" variant="sec-pine" id="loginBeforeReserve-btn" @click="loginBeforeReserve">
                <b-spinner class="spinner" variant="light" label="Spinning" v-if="loading" />
                {{$t('pay.reserve')}}
            </Button>
        </div>
        <div v-else>
            <Button class="new-btn reserve" variant="sec-pine" id="reserve-btn" @click="initPayment">
                <b-spinner class="spinner" variant="light" label="Spinning" v-if="loading" />
                {{$t('pay.reserve')}}
            </Button>
        </div>

        <!-- Modal Warning-->
        <b-modal
            id="modal-warning"
            centered
            header-border-variant="light"
            footer-border-variant="light"
            hide-footer
            size="lg"
            ref="modalWarnings"
        >
            <template v-slot:modal-title>
                <span class="icon-modal">
                    <i class="fas fa-exclamation-triangle" />
                </span>
                <span class="warn-title">{{$t('pay.warning')}}</span>
            </template>
            <div>
                <b-alert variant="warning" show v-for="(error, index) in errors" :key="index">
                    <span v-for="(message, msgIndex) in error" :key="msgIndex">{{ message }}</span>
                </b-alert>
            </div>

            <div class="complete-data-button" v-if="profileComplete">
                <Button class="new-btn" variant="sec-pine" @click="completeData">
                    {{$t('dashboard-side-menu.finish-registration')}}
                </Button>    
            </div>
            
        </b-modal>

        <!-- Modal Success -->
        <b-modal
            id="modal-success"
            centered
            header-border-variant="light"
            footer-border-variant="light"
            hide-footer
            size="lg"
            ref="modalSuccess"
            @hidden="redirectDashboard"
        >
            <template v-slot:modal-title>
                <span class="icon-modal-suc">
                    <i class="fas fa-check-circle" />
                </span>
                {{$t('pay.congratulations')}}
            </template>
            <div>
                <b-alert variant="success" show>{{$t('pay.success-message')}}</b-alert>
            </div>
        </b-modal>
        
        <!-- Payment Modal -->
        <b-modal
            id="modal-pago"
            centered
            size="xl"
            header-border-variant="light"
            footer-border-variant="light"
            hide-footer
            ref="modalPago"
            @show="resetStep"
            @hidden="cancelReservation"
        >
            <form-wizard
                :start-index.sync="stepIndex"
                title
                subtitle
                color="$hf-sec-pine"
                shape="hidden"
            >

                <!-- STEP 1 -->
                <tab-content title="paso 1">
                    <div class="container">
                        
                        <div class="row">
                            <div class="col-12">
                                <h2>{{$t('pay.title')}}</h2>
                            </div>
                        </div>

                        <!-- Carousel and Price Section -->
                        <div class="row mt-4">
                            
                            <!-- Pictures -->
                            <div class="col-12 col-lg-4 mb-3">
                                <CarouselList :idAlojamiento="this.$route.params.idalojamiento" />
                            </div>

                            <!-- Payment details -->
                            <div class="col-12 col-lg-4">
                                <div class="highlight-square">
                                    
                                    <h5>{{$t('simulator.payment-by-reservation')}}</h5>

                                    <!-- Stay -->
                                    <div v-if="rentalWarranty.amount == 0" class="payment-detail" >
                                        <div> {{ $t("simulator.stay") }}: </div>
                                        <div> {{ rentalPrice | convertAndFormatPrice }} </div>
                                    </div>

                                    <!-- Waranty -->
                                    <div v-else class="payment-detail" >
                                        <div> {{ $t("simulator.warranty") }}: </div>
                                        <div> {{ rentalWarranty | convertAndFormatPrice }} </div>
                                    </div>
                                    
                                    <!-- Service -->
                                    <div class="payment-detail">
                                        <div>{{ $t("simulator.service-charge") }}:</div>
                                        {{ rentalCommission | convertAndFormatPrice }}
                                    </div>

                                    <!-- Coupon -->
                                    <div v-if="discount.amount > 0" class="payment-detail">
                                        <strong>{{$t('simulator.coupon')}}:</strong>
                                        - {{ discount | convertAndFormatPrice }}
                                    </div>

                                    <!-- Total -->
                                    <div class="payment-detail">
                                        <strong>{{ $t("simulator.total") }}:</strong>
                                        {{ finalPrice | convertAndFormatPrice }} *
                                    </div>

                                    <!-- Gateway charge -->
                                    <div class="gateway-charge">
                                        * {{ $t("simulator.gateway-charge-not-included") }}
                                    </div>
                                    
                                </div>
                            </div>

                            <!-- Payment message -->
                            <div class="col-12 col-lg-4 highlight-square">
                                <h5>{{ $t("simulator.payment-during-stay") }}</h5>
                                <div>

                                    <p v-if="totalDays >= 30">
                                        {{ $t("simulator.you-will-pay") }}
                                        {{ rentalWarranty | convertAndFormatPrice }}
                                        {{ $t("simulator.monthly-message-1") }}
                                        {{ rentalCommission | convertAndFormatPrice }}
                                        {{ $t("simulator.monthly-message-2") }}
                                    </p>

                                    <p v-else>
                                        {{ $t("simulator.you-will-pay") }}
                                        {{ rentalPrice | convertAndFormatPrice }}
                                        {{ $t("simulator.daily-message-1") }}
                                        {{ rentalCommission | convertAndFormatPrice }}
                                    </p>

                                </div>
                        
                            </div>
                        </div>

                        <hr class="mt-5" />
                        
                        <!-- Coupons -->
                        <Coupon @coupon="setCoupon" :reservation="reservation" />
                        <hr class="mt-4 mb-4" />
                        
                        <!-- Info -->
                        <div class="d-flex justify-content-between">

                            <div>

                                <!-- Hint 1 -->
                                <div class="col-12 mt-3">
                                    <div
                                        class="circular-hint"
                                        v-b-tooltip.hover
                                        :title="$t('simulator.info-service')"
                                    >
                                        <i class="fas fa-info" />
                                    </div>
                                    <label>{{ $t("simulator.service4") }}</label>
                                </div>

                                <!-- Hint 2 -->
                                <div class="col-12 mt-3">
                                    <div
                                        class="circular-hint"
                                        v-b-tooltip.hover
                                        :title="$t('simulator.info-service')"
                                    >
                                        <i class="fas fa-info" />
                                    </div>
                                    <label>{{ $t("simulator.service5") }}</label>
                                </div>

                            </div>

                        </div>

                        <hr class="mt-4 mb-4" />

                    </div>
                </tab-content>

                <!-- STEP 2 -->
                <tab-content title="paso 2">
                    <div class="container mb-5">
                        
                        <div class="row">
                            <div class="col-12">
                                <h2>{{$t('pay.payment-detail')}}</h2>
                            </div>
                        </div>

                        <!-- Stay -->
                        <div v-if="rentalWarranty.amount == 0" class="row">
                            <div class="col-12">
                                <span class="float-left">{{$t('pay.stay')}}:</span>
                                <span class="float-right">{{ rentalPrice | convertAndFormatPrice }}</span>
                            </div>
                        </div>

                        <!-- Waranty -->
                        <div v-else class="row">
                            <div class="col-12">
                                <span class="float-left">{{$t('simulator.warranty')}}:</span>
                                <span class="float-right">{{ rentalWarranty | convertAndFormatPrice }}</span>
                            </div>
                        </div>
                        
                        <hr class="mt-2" />

                        <!-- Service -->
                        <div class="row">
                            <div class="col-12">
                                <span class="float-left">{{$t('simulator.service-charge')}}:</span>
                                <span class="float-right">{{ rentalCommission | convertAndFormatPrice }}</span>
                            </div>
                        </div>
                        
                        <hr class="mt-2" />

                        <!-- Coupon -->
                        <div v-if="discount.amount > 0" class="row">
                            <div class="col-12">
                                <span class="float-left">Cupon:</span>
                                <span class="float-right"> - {{ discount | convertAndFormatPrice }}</span>
                            </div>
                        </div>

                        <hr v-if="discount.amount > 0" class="mt-2" />

                        <!-- Total -->
                        <div class="row">
                            <div class="col-12">
                                <span class="float-left">{{$t('pay.total')}}*</span>
                                <span class="float-right">{{ finalPrice | convertAndFormatPrice }}</span>
                            </div>
                            <div class="not-included">* {{ $t('simulator.gateway-charge-not-included')}}</div>
                        </div>
                        
<!--                        <hr class="mt-5" />-->
                    </div>
                    
                </tab-content>
                
                <!-- STEP 3 -->
                <tab-content title="paso 3">
                    <div class="mt-5 mb-5">
                        <strong>{{$t('pay.attention')}}:</strong>
                        <p>{{$t('pay.payment-message')}}</p>
                    </div>
                    <hr class="mt-4"/>
                </tab-content>

                <!-- FOOTER -->
                <template slot="footer" slot-scope="props">
                    <div class=" row wizard-footer" style="text-align:right">
                        <div class="col-12 col-sm-9 text-left" v-if="stepIndex === 0">
                            <label class="toggleS mt-4">
                                <input class="toggleS__input" type="checkbox" v-model="terms" />
                                <span class="toggleS__label">
                                    <span class="toggleS__text">
                                        <a target="_blank" href='https://houseandflats.com/tyc'>
                                            <span v-html="$t('pay.accept-terms')"></span>
                                        </a>
                                        
                                    </span>
                                </span>
                            </label>
                        </div>
                        <div class="col text-center">
                            <wizard-button 
                                v-if="stepIndex !== 0"
                                @click.native="props.prevTab()"
                                class="wizard-btn py-3 mb-1 mx-1" 
                                id="reserve-back-button"
                                :disabled="!terms">
                                {{$t('pay.back')}}
                            </wizard-button>
                            <wizard-button 
                                v-if="stepIndex !== 2" @click.native="props.nextTab()"  v-show="!props.isLastStep || props.isLastStep"
                                class="wizard-btn py-3 mb-1 mx-1" 
                                id="reserve-continue-button"
                                :disabled="!terms">
                                {{$t('pay.continue')}}
                            </wizard-button>
                            <wizard-button 
                                v-if="stepIndex === 2" @click.native="reservar"  v-show="!props.isLastStep || props.isLastStep"
                                class="wizard-btn mx-1 py-3 mb-1" 
                                id="reserve-button"
                                :disabled="!terms || reserving">
                                <b-spinner variant="light" label="Spinning" style="width:17px; height: 17px" v-if="loading" />
                                {{$t('pay.reserve')}}
                            </wizard-button>
                        </div>
                    </div>
                    
                </template>

            </form-wizard>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import HotelDatePicker from "vue-hotel-datepicker";
import CarouselList from "@/components/Carousel/CarouselList";
import Coupon from '../Coupon/Coupon';
import { saveRedirectUrl } from "@/Utils/Utils";
import Button from "@/components/Ui/Button.vue";
import {trackEvent} from "@/Utils/tracking";

export default {
    name: "Pay",
    props: ["image", "moneda", "guest"],
    data() {
        return {
            stepIndex: 0,
            url: process.env.VUE_APP_S3_URL,
            api: process.env.VUE_APP_API_URL,
            loading: false,
            reserving: false,
            errors: {},
            success: false,
            terms: false,
            initReserva: false,
            huespedes: null,
            profileComplete: false,
            coupon: {
                percentage: 0,
                discount: 0
            }
        };
    },
    components: {
        Button,
        HotelDatePicker,
        CarouselList,
        Coupon
    },
    methods: {
        
        ...mapActions("simulator", [
            'getAvailable',
        ]),
        
        ...mapActions([
            "createReservation",
            "makeReservationRequest",
            "checkUserData"
        ]),
        
        completeData() {
            saveRedirectUrl(this.$route);
            this.$router.push({ name: 'editar-perfil' });
        },

        loginBeforeReserve () {
            
            // Save the url the user was seeing
            saveRedirectUrl(this.$route);
            
            this.$toasted.show(this.$t('login.need-login'), {
                theme: "toasted-primary",
                position: "bottom-right",
                duration: 2500,
                className: "toasted"
            });
            
            setTimeout(() => {
                this.$router.push({ name: "login" });
            }, 2500);
            
        },

        priceCurrency() {

            let currency = localStorage.getItem("currency_selected");

            switch (currency) {
                case "clp":
                    currency = "$CLP";
                    break;
                case "es_AR":
                    currency = "$ARS";
                    break;
                case "en_US":
                    currency = "$USD";
                    break;
                case "en_GB":
                    currency = "$GBP";
                    break;
                case "it_IT":
                    currency = "$EUR";
                    break;
                default:
                    currency = "$CLP";
            }
            return `${currency}`;
        },
        setCoupon(payload){
            this.coupon = {...this.coupon, ...payload };
        },
        
        resetStep() {
            this.stepIndex = 0;
            trackEvent('NewEvent', 'begin_checkout', {
                currency: this.accommodation.moneda,
                value: Number(this.simulation.total),
                items: {
                    item_id: this.accommodationId
                }
            });
        },

        initPayment() {
            
            this.errors = {};

            this.loading = true;

            let data = {
                idalojamiento: this.$route.params.idalojamiento,
                fecha_inicio: this.checkInDate,
                fecha_termino: this.checkOutDate,
                language: this.$i18n.locale,
            };

            if (data.fecha_inicio == null) {
                this.errors.fecha_inicio =
                    this.$i18n.locale === "es"
                        ? ["Por favor, introduzca la fecha de inicio"]
                        : ["Please enter the start date"];
            }

            if (data.fecha_termino == null) {
                this.errors.fecha_termino =
                    this.$i18n.locale === "es"
                        ? ["Por favor, introduzca la fecha de término"]
                        : ["Please enter the end date"];
            }
            
            this.profileComplete = false;

            this.checkUserData().then((response) => {
                if(response == false) {
                    this.errors = {};
                    this.errors.register = this.$i18n.locale === "es" ? ["Para hacer una reserva, ahora primero debe completar su registro."] : ["To make a reservation, you must now complete your registration."];
                    this.$refs["modalWarnings"].show();
                    this.loading = false;
                    this.profileComplete = true;
                }else{
                    if (this.isEmpty(this.errors)) {
                        this.createReservation(data)
                            .then(() => {
                                this.loading = false;
                                this.$refs["modalPago"].show();
                            })
                            .catch(error => {
                                this.success = false;
                                this.errors = error.response.data;
                                this.loading = false;
                                this.$refs["modalWarnings"].show();
                            });
                    } else {
                        this.loading = false;
                        this.$refs["modalWarnings"].show();
                    }
                }
            })
            
        },

        reservar() {
            this.loading = true;
            this.initReserva = true;
            this.reserving = true;
            this.makeReservationRequest({idreserva: this.reservation.idreserva, coupon: this.coupon })
                .then(response => {
                    this.loading = false;
                    this.$refs["modalSuccess"].show();
                    this.$refs["modalPago"].hide();
                    this.$store.commit("setReservation", {});
                })
                .catch(error => {});
        },

        isEmpty(obj) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) return false;
            }
            return true;
        },
        cancelReservation() {
            if (!this.initReserva) {
                this.$emit("canceled");
            }
            this.coupon.percentage = 0;
            this.coupon.discount = 0;
        },
        redirectDashboard() {
            this.$router.history.push({ name: "reservations-made"});
        },

    },
    computed: {
        
        ...mapState("simulator", [
            'accommodationId',
            'initDate',
            'endDate',
            'available',
            'simulation'
        ]),
        
        ...mapState('search', [
            'checkInDate',
            'checkOutDate',
        ]),

        // TODO: add the currency to the simulation response and remove this
        ...mapState("accommodation", [
            'accommodation',
        ]),
        
        ...mapState(["reservation", "accomodation", "totalDays"]),
        ...mapGetters(["isLoggedIn"]),

        rentalPrice() { 
            return { 'amount': this.reservation.costo, 'currency': this.reservation.moneda }
        },

        rentalCommission() {
            return { 'amount': this.reservation.comision, 'currency': this.reservation.moneda }
        },

        rentalWarranty() {
            return { 'amount': this.reservation.garantia, 'currency': this.reservation.moneda }
        },

        discount() {
            const discountAmount =  this.reservation.comision * this.coupon.percentage / 100;
            return { 'amount': discountAmount, 'currency': this.reservation.moneda };
        },

        finalPrice() {
            if (this.rentalWarranty.amount > 0) {
                const total = this.rentalWarranty.amount + this.rentalCommission.amount - this.discount.amount;
                return {'amount': total, 'currency': this.reservation.moneda}
            } else {
                const total = this.rentalPrice.amount + this.rentalCommission.amount - this.discount.amount;
                return {'amount': total, 'currency': this.reservation.moneda}
            }
        },
        
        disableReserveButton() {
            return this.checkInDate == null || this.checkOutDate == null || !this.available;
        },
    },
    
    watch: {
        'accommodationId': function() {
            if (this.accommodationId && this.accommodationId > 0 && this.initDate && this.endDate) {
                this.getAvailable();
            }
        },
        'initDate': function(newVal) {
            if (newVal && this.endDate && newVal < this.endDate && this.accommodationId && this.accommodationId > 0) {
                this.getAvailable();
            }
        },
        'endDate': function(newVal) {
            if (newVal && this.initDate && newVal > this.initDate && this.accommodationId && this.accommodationId > 0) {
                this.getAvailable();
            }
        },
    }

};
</script>

<style lang="scss">

.wizard-nav-pills {
    li.active {
        .wizard-icon,
        .stepTitle
        {
            color: $grey-50 !important;
        }
    }
}

.wizard-tab-content {
    padding: 30px 0 10px 0 !important;
}

/* TODO: move to a global style */
.toasted {
    background-color: $hf-sec-pine !important;
    color: #ffffff !important;
    font-weight: bold !important;
    min-height: 60px !important;

    @media (min-width: $md) {
        border-radius: $border-radius !important; 
    }
}
</style>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.complete-data-button {
    display: flex;
    justify-content: center;
}

.not-included {
    font-size: 0.9rem;
    margin-top: 20px;
    margin-left: 15px;
}

.wizard-btn {
    background-color: $hf-sec-pine !important;
    color: #fff;
}

.wizard-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-modal svg {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    color: #f5d678;
}

.warn-title {
    color: #90721a;
    position: absolute;
    margin-top: 15px;
}

.icon-modal-suc svg {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    color: #155724;
}

.highlight-square {
    background: #f7f7f7;
    padding: 10px;
    height: 100%;
}

.gateway-charge {
    margin-top: 20px;
    font-size: 0.9rem;
}

.payment-detail {
    display: flex;
    justify-content: space-between;
}

.bottom {
    display: flex;
    justify-content: space-between;
}

.bottom-info {
    label {
        padding-left: 10px;
    }
}

.simulator-profile-image {
    display: flex;
    justify-content: flex-end;

    .circular-portrait {
        width: 150px;
        height: 150px;
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid $hf-sec-pine;
    }

    .circular-portrait img {
        height: 100%;
        width: 100%;
    }
}

.circular-hint {
    text-align: center;
    border-radius: 50%;
    border: solid 1px rgb(196, 196, 196);
    width: 30px;
    height: 30px;
    padding: 2px;
    display: inline-block;
    margin-right: 10px;

    .fa-info {
        color: $hf-sec-pine;
    }

}

.reserve {
    width: 100%;
    font-size: 1.2em;
    font-weight: bold;
}

.icon-modal svg {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    color: #f5d678;
}

#modal-pago {
    padding: 0;
}

.spinner {
    position: absolute;
    margin-right: 140px;
    width: 1.5rem;
    height: 1.5rem;
}

</style>