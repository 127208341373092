<template>
	<div class="accommodation-container">

        <!-- Price -->
        <div class="price-container">
            <Price 
                :accommodation="accommodation"
                :monthlyPrice="monthlyPrice"
                :dailyPrice="dailyPrice" 
            />  
        </div>
        
        <!-- Calendar -->
		<div class="my-4 calendar-container">
			
            <!-- Spinner -->
            <div v-if="loadingCalendar" class="text-center">
                <b-spinner type="grow" class="mx-2"></b-spinner>
                <b-spinner type="grow" class="mx-2"></b-spinner>
                <b-spinner type="grow" class="mx-2"></b-spinner>
            </div>
            
            <!-- Calendar -->
            <div v-else>
                <DatePicker 
                    :disabledDates="disabledDates"
                    :minNights="minNights"
                />
                
                <div class="days-text">{{ $t("accommodation.min-nights", { nights: minNights }) }}</div>
            </div>
		</div>

<!--        &lt;!&ndash; Guest number selector &ndash;&gt;-->
<!--        <Guests :guests="accommodation.huespedes" />-->
        
        <!-- Reserve button -->
        <Pay class="mb-2 reserve-button"
            v-on:canceled="cancelReservation(reservation.idreserva)"
            :image="image" 
            :moneda="currency" 
            :guest="guest"  
        />
        
        <Simulator class="mb-4 simulator" :moneda="currency" />

        <!-- Ask visit button -->
        <Visit class="mb-5 visit-button" :accommodation="accommodation" />
        
        <!-- Conversation button -->
        <div v-if="isLoggedIn" class="w-100 conversation-button-container">
            <Button 
                @click="visible = true" 
                class="new-btn init-conversation" 
                variant="primary">
                {{ $t('conversation.init-conversation') }}
            </Button>
        </div>
        <div v-else class="w-100 conversation-button-container">
            <Button 
                @click="loginBeforeSendMEssage" 
                class="new-btn init-conversation" 
                variant="primary">
                {{ $t('conversation.init-conversation') }}
            </Button>
        </div>

        <InitConversationModal
            :visible="visible"
            :accommodation="accommodation"
            @hide="visible = false"
        />
        
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import HotelDatePicker from "vue-hotel-datepicker";
import Pay from "@/components/Payment/Pay";
import axios from "axios";
import { calculateOccupiedDays } from "@/Utils/Utils";
import priceMixin from "@/mixins/priceMixin";
import Guests from "@/components/DetalleAlojamiento/subcomponents/Guests.vue";
import Price from "@/components/DetalleAlojamiento/subcomponents/Price.vue";
import Simulator from "@/components/DetalleAlojamiento/subcomponents/Simulator.vue";
import Visit from "@/components/DetalleAlojamiento/subcomponents/Visit.vue";
import DatePicker from "@/new-components/DatePicker/DatePicker.vue";
import Button from "@/components/Ui/Button.vue";
import InitConversationModal from "@/pages/Conversation/InitConversationModal.vue";
import { saveRedirectUrl } from "@/Utils/Utils";

export default {
    name: "BookingDetails",
	props: [
        "image", 
        "accommodation", 
    ],
    mixins: [ priceMixin ],
	data() {
		return {
			guest: 1,
			dates: [],
			datepickerValue: null,
			loadingCalendar: true,
			minNights: null,
            disabledDates: [],
            visible: false
		};
	},
	components: {
        InitConversationModal,
        Button,
        DatePicker,
        Visit,
        Price,
        Guests,
		HotelDatePicker,
		Pay,
		Simulator,
	},
	methods: {

		...mapActions(["cancelReservation"]),
        
		loadReservedDates() {
			this.loadingCalendar = true;
			axios
				.get(
					process.env.VUE_APP_API_URL +
						"accommodations/unavailable_dates/" +
						this.$route.params.idalojamiento
				)
				.then((response) => {
					this.dates = response.data;
					axios
						.get(
							process.env.VUE_APP_API_URL +
								"accommodations/getAccomodationFront/" +
								this.$route.params.idalojamiento
						)
						.then((value) => {
							this.minNights = value.data.accomodation.tiempo_min;							
						})
                        .finally(() => {
                            this.disabledDates = calculateOccupiedDays(this.dates, this.minNights);
                            this.loadingCalendar = false;
                        });
				});
		},

        loginBeforeSendMEssage () {
            
            // Save the url the user was seeing
            saveRedirectUrl(this.$route);
            
            this.$toasted.show(this.$t('login.need-login'), {
                theme: "toasted-primary",
                position: "bottom-right",
                duration: 2500,
                className: "toasted"
            });
            
            setTimeout(() => {
                this.$router.push({ name: "login" });
            }, 2500);
            
        },
	},
    
	computed: {
        ...mapState(["reservation"]),
        ...mapGetters(["isLoggedIn"]),
	},
	created() {
        this.loadReservedDates();
	},
};
</script>


<style scoped lang="scss">
@import "@/new-assets/styles";

.accommodation-container {
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between !important;
    padding: 15px 0;
    height: 100%;
    @media(min-width: $md) {
        border: 1px solid #ddd;
        padding: 15px;
    }

    .price-container {
        display: flex;
        align-items: center;
        padding: 15px 0 25px 0px;
    }

    // .price-container,
    // .calendar-container,
    // .reserve-button,
    // .simulator,
    // .visit-button,
    // .conversation-button-container {
    //     flex: 1;
    // }

}

.days-text {
    font-size: 0.9em;
    margin-bottom: 10px;
}

.n-guests-label {
    font-size: 1.4em;
    margin-top: 10px;
    text-align: center;
}

.reservation-per-day {
    color: gray;
    font-size: 0.6em;
}


.datepicker {
    z-index: 1021 !important;
}

::v-deep .datepicker.datepicker--open {
    @media (min-width: $lg) {
        left: -365px !important;
        margin-top: 15px;
    }
}
::v-deep .datepicker.datepicker {
    transition: none !important;
}

// Next month button
.next--mobile {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    background: $hf-sec-pine;
    height: $see-more-button-height;
    color: #FFFFFF;
    font-size: 1.2rem;
    font-style: italic;
    z-index: 1021 !important;
}

.init-conversation {
    font-size: 1.2em;
    font-weight: bold;
    width: 100%;
}


</style>
