<template>
	<div class="app-container" id="page" @click="resetVersionCheck">
		<DefaultLayout :showDashboard="showDashboard">
			<router-view />
		</DefaultLayout>
        <Bot v-if="!hideBot" id="whatsapp-chat" />
        <AppVersionCheck />
        <CookiesConsent />
	</div>
</template>

<script>
import CookiesConsent from "@/components/Cookies/Cookies";
import WebSitePopUp from "@/components/PopUps/WebSitePopUp";
import Bot from "@/components/Bot/Bot.vue";
import AppVersionCheck from "@/components/AppVersionCheck/AppVersionCheck.vue";
import store from '@/store'
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
	components: {
        CookiesConsent,
        WebSitePopUp,
        Bot,
        AppVersionCheck,
        DefaultLayout
	},
	computed: {
		showDashboard() {
			return this.$route.meta.showDashboard === true;
		},
        hideBot() {
            return this.$route.meta.hideBot;
        }
	},
    methods: {
        resetVersionCheck() {
            store.dispatch('appVersionCheck/setVersionChecked', false);
        },
    }
};
</script>

<style>

.app-container {
    font-family: 'Instrument Sans', sans-serif;
}

</style>

