<template>
  <button class="navbar-toggler menu-icon" :class="{ active: isOpen }" @click="$emit('toggle')">
    <span class="line"></span>
    <span class="line"></span>
  </button>
</template>

<script>
export default {
  name: 'OpenCloseButton',
  props: {
    isOpen: {
        type: Boolean,
        default: false
    },
  }
}
</script>

<style lang="scss" scoped>

.menu-icon {
  background-color: $hf-primary;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 102;

  &:hover {
    background-color: darken($hf-primary, 10%);
  }

  .line {
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: white;
    border-radius: 1px;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &:nth-child(1) {
      transform: translateY(-5px);
    }

    &:nth-child(2) {
      transform: translateY(5px);
    }
  }

  &.active {
    .line {
      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }
}

</style>