<template>
    <div>
        <router-link
            class="new-btn btn--sec-pine-white request-visit"
            id="request-visit-button"
            :to="{ name: 'request-visit', params: { accommodationId: accommodation.idalojamiento }}"
        >
            {{ $t("reservation.request-visit") }}
        </router-link>
    </div>
</template>

<script>
export default {
    name: "Visit",
    props: {
        accommodation: Object
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.request-visit {
    width: 100%;
    font-size: 1.2em;
    font-weight: bold;
}
</style>
