export const socialMediaLinks = {
    facebook: "https://www.facebook.com/HouseAndFlats/",
    instagram: "https://www.instagram.com/houseandflats/",
    linkedin: "https://www.linkedin.com/company/house-and-flats-renting/",
    tiktok: "https://www.tiktok.com/@houseandflats",
    whatsapp: `https://wa.me/5491158860114`
  };

export const whatsappBot = {
    phone: `13055100028`
};


export const appRoutes = {
    privacy: "/privacidad",
    faq: "/faq",
    tyc: "/tyc",
    about: "/about",
    investment: "/investment",
    home: "/",
    dashboard: "/dashboard",
    login: "/login"
  };

export const supportedLanguages = ['en', 'es', 'es-ES', 'es-CL'];
