import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'

// Routes
import authRoutes from "@/routes/auth";
import accommodationVisit from "@/routes/accommodationVisit";
import publication from "@/routes/publication";
import user from "@/routes/user";
import reservation from "@/routes/reservation";
import searchQueries from "@/routes/searchQuery";
import conversation from "@/routes/conversation";

// Supported languages
import { i18n } from '@/main';
import { supportedLanguages } from '@/config';



import Home from '@/pages/General/Home'
import About from '@/pages/General/About'
import DetalleAlojamiento from '@/pages/General/DetalleAlojamiento'
import Privacy from '@/pages/General/Privacy'
import Faq from '@/pages/General/Faq'
import TermsConditions from '@/pages/General/TermsConditions'
import Contact from '@/pages/General/Contact'
import Investment from '@/pages/General/Investment'
import Admin from '@/pages/Dashboard/Admin'
import SearchResults from '@/pages/General/SearchResults'

import MisAlojamientos from '@/pages/Dashboard/MisAlojamientos';
import DatosBancarios from './pages/Dashboard/BankInformation.vue'
import EditarPerfil from '@/pages/Dashboard/EditarPerfil'
import Favoritos from '@/pages/Dashboard/Favoritos';
import BlockDates from '@/pages/Dashboard/BlockDates'
import Coupon from '@/pages/Dashboard/Coupon'
import WizardAlojamiento from '@/pages/Dashboard/WizardAlojamiento'
import ManageData from '@/pages/Dashboard/ManageData'
import Bugs from '@/pages/General/Bug';
import Highlight from '../src/components/Admin/Highlight/Highlight'
import Visits from '../src/components/Admin/Visits/Visits'
import Reservations from '../src/components/Admin/Reservations/Reservations'
import Ical from '../src/components/Admin/Ical/Ical'
import FacebookDeleteStatus from './components/Facebook/FacebookDeleteStatus'

import Success from '@/components/Wizard/Success'

import Reserva from '@/components/Payment/Reserva'
import Payment from '@/components/Payment/Payment';
import PaymentResponse from '@/components/Payment/PaymentResponse'

import qs from 'qs';

Vue.use(Router)

let router = new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    base: process.env.BASE_URL,
    routes: [
        ...authRoutes,
        ...accommodationVisit,
        ...publication,
        ...user,
        ...reservation,
        ...searchQueries,
        ...conversation,
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/informar-error',
            name: 'bug',
            component: Bugs
        },
        {
            path: '/registro-alojamiento',
            name: 'registro-alojamiento',
            component: WizardAlojamiento,
            meta: {
                requiresAuth: true,
                showDashboard: true,
                hideBot: true
            }
        },
        {
            path: '/editar-alojamiento/:idalojamiento',
            name: 'editar-alojamiento',
            component: WizardAlojamiento,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/my-accommodations',
            name: 'my-accommodations',
            component: MisAlojamientos,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/actualizado_con_exito',
            name: 'Update',
            component: Success,
            props: { update: true },
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/editar-perfil',
            name: 'editar-perfil',
            component: EditarPerfil,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/datos-bancarios',
            name: 'Datos bancarios',
            component: DatosBancarios,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/alojamiento/:idalojamiento/:fecha_inicio?/:fecha_termino?',
            name: 'detalle-alojamiento',
            component: DetalleAlojamiento
        },
        {
            path: '/bloquear/:id',
            name: 'Block-Dates',
            component: BlockDates,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/reserva/:action/:id/:token',
            name: 'reserva',
            component: Reserva
        },
        {
            path: '/reserva-status/:status',
            name: 'status-reserva',
            component: PaymentResponse
        },
        {
            path: '/pagos/:id/:token',
            name: 'pagos',
            component: Payment
        },
        {
            path: '/search',
            name: 'search',
            component: SearchResults,
            props: (route) => ({ query: route.query })
        },
        {
            path: '/contacto',
            name: 'contact',
            component: Contact
        },
        {
            path: '/investment',
            name: 'investment',
            component: Investment
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/favoritos',
            name: 'favoritos',
            component: Favoritos,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/admin',
            name: 'admin',
            component: Admin,
            meta: {
                requiresAuth: true,
                showDashboard: true,
                hideBot: true
            }
        },
        {
            path: '/highlighted',
            name: 'highlight',
            component: Highlight,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/coupon',
            name: 'coupon',
            component: Coupon,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/icalendar',
            name: 'ical',
            component: Ical,
            meta: {
                requiresAuth: true,
                showDashboard: true,
                hideBot: true
            }
        },
        {
            path: '/visits',
            name: 'visits',
            component: Visits,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/manage-data',
            name: 'manage-data',
            component: ManageData,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/reservations',
            name: 'reservations',
            component: Reservations,
            meta: {
                requiresAuth: true,
                showDashboard: true
            }
        },
        {
            path: '/privacidad',
            name: 'Privacy',
            component: Privacy
        },
        {
            path: '/faq',
            name: 'Faq',
            component: Faq
        },
        {
            path: '/tyc',
            name: 'TermsConditions',
            component: TermsConditions
        },
        {
            path: '/facebook/delete/:token',
            name: 'facebook-delete',
            component: FacebookDeleteStatus
        }
    ],
    // set custom query resolver
    parseQuery(query) {
        return qs.parse(query);
    },
    stringifyQuery(query) {
        var result = qs.stringify(query);

        return result ? ('?' + result) : '';
    }
})

router.beforeEach((to, from, next) => {
    
    // Login guard
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            if (to.matched.some(record => record.meta.requiresAdmin)) {
                if (store.getters.isAdmin) {
                    next();
                } else {
                    next('/');
                }
            } else {
                next();
            }
            return;
        }
        next('/login');
    } else {
        // Lenguage guard

        // Extract the language from the route parameters
        const lang = to.params.lang;

        if (lang) {
            // Check if the language is in the list of supported languages
            if (!supportedLanguages.includes(lang)) {
                // Redirect to the default language if it's not supported
                return next({path: `/en${to.fullPath.substring(3)}`});
            }

            // Set the language for vue-i18n
            if (lang !== i18n.locale) {
                i18n.locale = lang;  // Dynamically change the language
            }
        }
        
        next();
    }
})


export default router
