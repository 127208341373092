<template>
    <b-modal
        v-model="visible"
        class="message-modal"
        size="md"
        @hidden="handleHidden"
        @show="success = false"
    >
        <template #modal-header>
            <div class="modal-header d-flex justify-content-between align-items-center w-100">
                <div class="d-flex align-items-center">
                    <img :src="photoUrl" class="avatar mr-2" />
                    <h5 class="m-0">{{ getJsonData(accommodation.nombre) }}</h5>
                </div>
            </div>
            <b-button variant="link" class="close-button" @click="handleHidden">&times;</b-button>
        </template>
        
        <div class="message-modal__content">

            <!-- Message -->
            <ValidatedTextArea
                fieldName="message"
                v-model="formData.message"
                label=""
                :placeholder="$t('conversation.message-placeholder')"
                :validationObject="$v.formData.message"
            />

            <div class="success-feedback">
                <div v-if="success" class="success-text">
                    {{ $t('conversation.success') }}
                </div>
            </div>
            
        </div>
        <template #modal-footer>
            
            <!-- Send button -->
            <Button v-if="!success" @click="handleSubmit" class="new-btn send-button" variant="primary" :disabled="loading">
                {{ $t('conversation.send') }}
            </Button>

            <div v-else class="sent-buttons">
                <Button @click="handleHidden" class="new-btn message-sent-close px-1" variant="sec-carbon">
                    {{ $t('conversation.close') }}
                </Button>
                <Button @click="goToMessages" class="new-btn go-to-messages px-1" variant="primary">
                    {{ $t('conversation.go-to-messages') }}
                </Button>
            </div>
            
        </template>
    </b-modal>
</template>

<script>
import ValidatedTextArea from "@/components/ValidatedFields/ValidatedTextArea.vue";
import { validations } from "@/components/ValidatedFields/conversationRules";
import Button from "@/components/Ui/Button.vue";
import { mapActions } from "vuex";

export default {
    name: "InitConversationModal",
    components: {
        Button, 
        ValidatedTextArea
    },
    props: {
        visible: Boolean,
        accommodation: Object,
    },
    data() {
        return {
            url: process.env.VUE_APP_S3_URL,
            formData: {
                message: ''
            },
            success: false,
            loading: false,
        };
    },
    validations: validations,
    methods: {
        ...mapActions("conversations", [
            'createConversation'
        ]),
        handleHidden() {
            this.$emit("hide", false);
        },
        getJsonData(value) {
            return JSON.parse(value)[this.$i18n.locale];
        },
        async handleSubmit() {

            // Validate form
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.modalMessage = this.$t('validations.alert');
                this.showAlert = true;
                window.scrollTo(0, 0);
                
                return;
            }

            this.loading = true;
            
            const response = await this.createConversation({
                guestId: this.userId,
                accommodationId: this.accommodation.idalojamiento,
                message: this.formData.message
            });
            
            // If the response code is 201, the conversation was created successfully, show a message
            if (response.status === 201) {
                this.success = true;
            }

            this.loading = false;
        },

        goToMessages() {
            this.$router.push({ name: 'conversation' });
        }
    },
    computed: {
        userId() {
            return this.$store.state.user.idusuario;
        },
        photoUrl() {
            const photo = this.accommodation.photos[0];
            return photo.url.indexOf('http') > -1 ? photo.url : this.url + photo.url;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

::v-deep .modal-header {
    border: none;
    padding-bottom: 0;
}
::v-deep .close-button {
    font-size: 2rem;
    color: #333;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    padding: 0 10px 0 30px;
    text-decoration: none !important;
}

::V-deep .modal-content {
    border-radius: $border-radius-new !important;
}

::v-deep .modal-body {
    padding-top: 0px;
}

::v-deep .modal-footer {
    border: none;
}

::v-deep .modal-content {
    border-radius: $border-radius-new;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.send-button {
    width: 100%;
    font-size: 1.2rem;
}

.sent-buttons {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    .message-sent-close {
        width: 40%;
    }

    .go-to-messages {
        width: 40%;
    }

}
.success-feedback {
    margin-top: 10px;
    text-align: center;
}

.success-text {
    color: $hf-office;
    font-size: 0.9rem;
}

</style>
