import apiUrls from "@/new-constants/apiUrls";
import axios from "axios";
import * as types from "./types";

export default {

    async fetchConversationsAdmin({ commit }) {
        
        const url = `${apiUrls.conversationsAdmin}`;

        try {
            const response = await axios.get(url);
            
            commit(types.SET_CONVERSATIONS, response.data);
            
        } catch (error) {
            console.error("Hubo un error al obtener las conversaciones:", error);
        }
    },

    async fetchConversations({ commit }) {
        
        const url = `${apiUrls.conversations}`;

        try {
            const response = await axios.get(url);
            
            commit(types.SET_CONVERSATIONS, response.data);
            
        } catch (error) {
            console.error("Hubo un error al obtener las conversaciones:", error);
        }
    },

    async fetchMessages({ commit }, conversationId) {

        const url = `${apiUrls.messages}/${conversationId}`;

        let response;
        
        try {
            response = await axios.get(url);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }

        commit(types.SET_MESSAGES, response.data.messages);
        commit(types.SET_SELECTED_CONVERSATION, conversationId);

    },

    async fetchMessagesAdmin({ commit }, conversationId) {

        const url = `${apiUrls.messagesAdmin}/${conversationId}`;

        let response;
        
        try {
            response = await axios.get(url);
        } catch (error) {
            console.error("Error fetching messages:", error);
        }

        commit(types.SET_MESSAGES, response.data.messages);
        commit(types.SET_SELECTED_CONVERSATION, conversationId);

    },

    async sendMessage({ commit, state, dispatch }, { conversationId, message }) {

        const url = `${apiUrls.messages}`;
        const data = { 
            conversation_id: conversationId, 
            message: message 
        };

        try {
            const response = await axios.post(url, data);

            return response;

        } catch (error) {
            console.error("Hubo un error al enviar el mensaje:", error);
        }
    },

    async createConversation({ commit, state, dispatch }, { guestId, accommodationId, message }) {

        const url = `${apiUrls.conversations}`;
        const data = { 
            guest_id: guestId, 
            accommodation_id: accommodationId,
            message: message
        };

        try {
            return axios.post(url, data);

        } catch (error) {
            console.error('Hubo un error al comunicarse con el servidor', error);
        }
    },
    
    addMessage({ commit, state, dispatch }, message) {
        commit(types.ADD_MESSAGE, message);
    },

    addConversation({ commit, state, dispatch }, conversation) {
        commit(types.ADD_CONVERSATION, conversation);
    }

};
