import * as types from './types';

export default {
    [types.SET_CONVERSATIONS](state, conversations) {
        state.conversations = conversations;
    },
    [types.SET_MESSAGES](state, messages) {
        state.messages = messages;
    },
    [types.SET_SELECTED_CONVERSATION](state, conversationId) {
        state.selectedConversation = state.conversations.find(
            (conv) => conv.id === conversationId
        ) || null;
    },
    [types.ADD_MESSAGE](state, message) {
        state.messages.push(message);
    },
    [types.ADD_CONVERSATION](state, conversation) {
        state.conversations.push(conversation);
    }
    
};