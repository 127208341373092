export const locationsList = [
    // Argentina
    {
        comuna: "Buenos Aires, toda la ciudad",
        long_name: "Buenos Aires, toda la ciudad",
        country: "Argentina",
        latitud: -34.6036844,
        longitud: -58.3815591,
        northeast: "-34.52654636106313,-58.33514472475672",
        southwest: "-34.70510112811848,-58.53145220760162",
        polygon: false,
        image_filename: "buenos_aires_toda_la_ciudad",
        search_query_enabled: true,
        home_search_query_enabled: true
    },
    {
        comuna: "Buenos Aires, Almagro",
        long_name: "Buenos Aires, Almagro",
        country: "Argentina",
        latitud: -34.6114447,
        longitud: -58.4209936,
        northeast: "-34.59770274704947,-58.4119189485255",
        southwest: "-34.62207434590157,-58.43333402722834",
        polygon: true,
        image_filename: "buenos_aires_almagro",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Belgrano",
        long_name: "Buenos Aires, Belgrano",
        country: "Argentina",
        latitud: -34.5620844,
        longitud: -58.4566864,
        northeast: "-34.5359311016451,-58.42820373959617",
        southwest: "-34.57500839276119,-58.47336842447922",
        polygon: true,
        image_filename: "buenos_aires_belgrano",
        search_query_enabled: true
    },
    {
        comuna: "Buenos Aires, Boedo",
        long_name: "Buenos Aires, Boedo",
        country: "Argentina",
        latitud: -34.6305379,
        longitud: -58.4192214,
        northeast: "-34.62064223061657,-58.41048865654462",
        southwest: "-34.64020022426429,-58.42779529821492",
        polygon: true,
        image_filename: "buenos_aires_boedo",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Caballito",
        long_name: "Buenos Aires, Caballito",
        country: "Argentina",
        latitud: -34.6159245,
        longitud: -58.4406027,
        northeast: "-34.60267336034419,-58.42675582585225",
        southwest: "-34.63063906647759,-58.46270463067378",
        polygon: true,
        image_filename: "buenos_aires_caballito",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Chacarita",
        long_name: "Buenos Aires, Chacarita",
        country: "Argentina",
        latitud: -34.5859985,
        longitud: -58.45443629999999,
        northeast: "-34.57829453869661,-58.43849764188068",
        southwest: "-34.59783466793689,-58.4669576235153",
        polygon: true,
        image_filename: "buenos_aires_chacarita",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Colegiales",
        long_name: "Buenos Aires, Colegiales",
        country: "Argentina",
        latitud: -34.5760225,
        longitud: -58.4483554,
        northeast: "-34.5661081700822,-58.43993891105978",
        southwest: "-34.58319172322628,-58.46335554961188",
        polygon: true,
        image_filename: "buenos_aires_colegiales",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Flores",
        long_name: "Buenos Aires, Flores",
        country: "Argentina",
        latitud: -34.6374837,
        longitud: -58.4601452,
        northeast: "-34.61388557303465,-58.43742236310418",
        southwest: "-34.65684135871686,-58.47780721653619",
        polygon: true,
        image_filename: "buenos_aires_flores",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, La Boca",
        long_name: "Buenos Aires, La Boca",
        country: "Argentina",
        latitud: -34.6344961,
        longitud: -58.36313370000001,
        northeast: "-34.62500414702805,-58.3527324774575",
        southwest: "-34.65017266519008,-58.37065825565539",
        polygon: true,
        image_filename: "buenos_aires_la_boca",
        search_query_enabled: true
    },
    {
        comuna: "Buenos Aires, Nuñez",
        long_name: "Buenos Aires, Nuñez",
        country: "Argentina",
        latitud: -34.5447906,
        longitud: -58.4598789,
        northeast: "-34.52654636106313,-58.44643338341963",
        southwest: "-34.55892837545181,-58.47575577415701",
        polygon: true,
        image_filename: "buenos_aires_nunez",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Palermo",
        long_name: "Buenos Aires, Palermo",
        country: "Argentina",
        latitud: -34.5780655,
        longitud: -58.4265317,
        northeast: "-34.5490119771075,-58.39298649435829",
        southwest: "-34.59785472473616,-58.44904437613813",
        polygon: true,
        image_filename: "buenos_aires_palermo",
        search_query_enabled: true
    },
    {
        comuna: "Buenos Aires, Parque Patricios",
        long_name: "Buenos Aires, Parque Patricios",
        country: "Argentina",
        latitud: -34.6362319,
        longitud: -58.4004841,
        northeast: "-34.62725286228006,-58.38988142140611",
        southwest: "-34.64859488218676,-58.41343650917931",
        polygon: true,
        image_filename: "buenos_aires_parque_patricios",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Puerto Madero",
        long_name: "Buenos Aires, Puerto Madero",
        country: "Argentina",
        latitud: -34.6177194,
        longitud: -58.3620561,
        northeast: "-34.59467401608841,-58.33514472475672",
        southwest: "-34.63257327938826,-58.36939299381784",
        polygon: true,
        image_filename: "buenos_aires_puerto_madero",
        search_query_enabled: true
    },
    {
        comuna: "Buenos Aires, Recoleta",
        long_name: "Buenos Aires, Recoleta",
        country: "Argentina",
        latitud: -34.5873825,
        longitud: -58.39727620000001,
        northeast: "-34.56989504893882,-58.3766853295508",
        southwest: "-34.59976039338088,-58.41600042107622",
        polygon: true,
        image_filename: "buenos_aires_recoleta",
        search_query_enabled: true
    },
    {
        comuna: "Buenos Aires, Retiro",
        long_name: "Buenos Aires, Retiro",
        country: "Argentina",
        latitud: -34.5896061,
        longitud: -58.3802473,
        northeast: "-34.57950283306921,-58.36566692499179",
        southwest: "-34.59929285922635,-58.38790973629558",
        polygon: true,
        image_filename: "buenos_aires_retiro",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Saavedra",
        long_name: "Buenos Aires, Saavedra",
        country: "Argentina",
        latitud: -34.5545188,
        longitud: -58.4915986,
        northeast: "-34.53881614175945,-58.46818929604658",
        southwest: "-34.56988232735495,-58.51013033385347",
        polygon: true,
        image_filename: "buenos_aires_saavedra",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, San Telmo",
        long_name: "Buenos Aires, San Telmo",
        country: "Argentina",
        latitud: -34.6218351,
        longitud: -58.3713942,
        northeast: "-34.61562665028076,-58.36309091893586",
        southwest: "-34.62949370416774,-58.37742524370558",
        polygon: true,
        image_filename: "buenos_aires_san_telmo",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Villa Crespo",
        long_name: "Buenos Aires, Villa Crespo",
        country: "Argentina",
        latitud: -34.5947209,
        longitud: -58.4442897,
        northeast: "-34.58869051507484,-58.42336245346457",
        southwest: "-34.60761551591774,-58.45893473989321",
        polygon: true,
        image_filename: "buenos_aires_villa_crespo",
        search_query_enabled: false
    },
    {
        comuna: "Buenos Aires, Villa Urquiza",
        long_name: "Buenos Aires, Villa Urquiza",
        country: "Argentina",
        latitud: -34.5703983,
        longitud: -58.49138680000001,
        northeast: "-34.55910422895678,-58.46808126935032",
        southwest: "-34.58481817124689,-58.50941221855233",
        polygon: true,
        image_filename: "buenos_aires_villa_urquiza",
        search_query_enabled: false
    },
    {
        comuna: "Provincia de Buenos Aires, Mar del Plata",
        long_name: "Provincia de Buenos Aires, Mar del Plata",
        country: "Argentina",
        latitude: -38.0054771,
        longitude: -57.5426106,
        northeast: "-37.90878298257896,-57.51795201968563",
        southwest: "-38.12635032389073,-57.68311614064216",
        polygon: true,
        home_search_query_enabled: true
    },
    {
        comuna: "Córdoba",
        long_name: "Córdoba",
        country: "Argentina",
        latitud: -31.42008329999999,
        longitud: -64.1887761,
        northeast: "-31.30646103489714,-64.06909932034236",
        southwest: "-31.49244535901042,-64.3193253509635",
        polygon: false,
        image_filename: "cordoba",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Mendoza",
        long_name: "Mendoza",
        country: "Argentina",
        latitud: -32.8894587,
        longitud: -68.8458386,
        northeast: "-32.8159953407433,-68.6989778112199",
        southwest: "-32.95121452510103,-68.94840178772135",
        polygon: false,
        image_filename: "mendoza",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Neuquén",
        long_name: "Neuquén",
        country: "Argentina",
        latitud: -38.9516784,
        longitud: -68.0591888,
        northeast: "-38.89328005153885,-68.00814816027368",
        southwest: "-38.98918905814746,-68.22265341538633",
        polygon: false,
        image_filename: "neuquen",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    // Chile
    {
        comuna: "Barrio Bellavista",
        long_name: "Barrio Bellavista",
        country: "Chile",
        latitud: -33.4322222,
        longitud: -70.6363889,
        northeast: "-33.4308343197085,-70.63502466970849",
        southwest: "-33.4335322802915,-70.63772263029149",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false
    },
    {
        comuna: "Barrio Italia",
        long_name: "Barrio Italia",
        country: "Chile",
        latitud: -33.4382715,
        longitud: -70.6410854,
        northeast: "-33.43597858629745,-70.63658439597023",
        southwest: "-33.44220483545023,-70.64470888629934",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false
    },
    {
        comuna: "Barrio Lastarria",
        long_name: "Barrio Lastarria",
        country: "Chile",
        latitud: -33.43782629999999,
        longitud: -70.639476,
        northeast: "-33.4367501197085,-70.63866115",
        southwest: "-33.4394480802915,-70.64192055000001",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false
    },
    {
        comuna: "Barrio Paris-Londres",
        long_name: "Barrio Paris-Londres",
        country: "Chile",
        latitud: -33.4446231,
        longitud: -70.64842879999999,
        northeast: "-33.44327411970851,-70.6470798197085",
        southwest: "-33.4459720802915,-70.6497777802915",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false
    },
    {
        comuna: "Barrio Yungay",
        long_name: "Barrio Yungay",
        country: "Chile",
        latitud: -33.4429112,
        longitud: -70.6731231,
        northeast: "-33.4349023579793,-70.66759799269593",
        southwest: "-33.4507413769721,-70.68024566575444",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false
    },
    {
        comuna: "Concepción",
        long_name: "Concepción",
        country: "Chile",
        latitud: -36.8201352,
        longitud: -73.0443904,
        northeast: "-36.76354254601518,-72.98195795982652",
        southwest: "-36.88854165008797,-73.08413320087756",
        polygon: false,
        image_filename: "concepcion",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Isla de Pascua",
        long_name: "Isla de Pascua",
        country: "Chile",
        latitud: -27.112723,
        longitud: -109.3496865,
        northeast: "-27.05473189047591,-109.2276952472208",
        southwest: "-27.19722795145251,-109.4499342192965",
        polygon: false,
        image_filename: "isla_de_pascua",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Las Condes",
        long_name: "Las Condes",
        country: "Chile",
        latitud: -33.4161088,
        longitud: -70.5340623,
        northeast: "-33.364206818506,-70.42728650953026",
        southwest: "-33.48606993664915,-70.60778616984688",
        polygon: false,
        image_filename: "concepcion",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "La Reina",
        long_name: "La Reina",
        country: "Chile",
        latitud: -33.4418601,
        longitud: -70.5345553,
        northeast: "-33.4288361198588,-70.51125278706823",
        southwest: "-33.46555683383467,-70.58451646547671",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: false
    },
    {
        comuna: "Lo Barnechea",
        long_name: "Lo Barnechea",
        country: "Chile",
        latitud: -33.3527172,
        longitud: -70.5185506,
        northeast: "-33.31175167016673,-70.42591376089433",
        southwest: "-33.37284987557622,-70.5753078428751",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Ñuñoa",
        long_name: "Ñuñoa",
        country: "Chile",
        latitud: -33.4549175,
        longitud: -70.5982915,
        northeast: "-33.43380044265094,-70.5707508537598",
        southwest: "-33.47495485253308,-70.63155961883908",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: false
    },
    {
        comuna: "Providencia",
        long_name: "Providencia",
        country: "Chile",
        latitud: -33.4314428,
        longitud: -70.609455,
        northeast: "-33.40341195293673,-70.58225018184636",
        southwest: "-33.44984106242084,-70.63624947294747",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: false
    },    
    // {
    //     comuna: "Santiago de Chile, toda la ciudad",
    //     long_name: "Santiago de Chile, toda la ciudad",
    //     country: "Chile",
    //     latitud: -33.4488897,
    //     longitud: -70.6692655,
    //     northeast: "-33.27138735393422,-70.42678074132388",
    //     southwest: "-33.67418853776274,-70.83288189033638",
    //     polygon: false,
    //     image_filename: "santiago_de_chile_toda_la_ciudad",
    //     search_query_enabled: true,
    //     home_search_query_enabled: true
    // },
    {
        comuna: "Santiago de Chile, centro",
        long_name: "Santiago de Chile, centro",
        country: "Chile",
        latitud: -33.4452217,
        longitud: -70.6573657,
        northeast: "-33.42569401113151,-70.62505383537598",
        southwest: "-33.47855073404921,-70.69204190884864",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: false
    },
    {
        comuna: "Valparaíso",
        long_name: "Valparaíso",
        country: "Chile",
        latitud: -33.047238,
        longitud: -71.61268849999999,
        northeast: "-33.01781650321404,-71.55976843955561",
        southwest: "-33.08258438867568,-71.66232867881529",
        polygon: false,
        image_filename: "valparaiso",
        search_query_enabled: true,
        home_search_query_enabled: true
    },
    {
        comuna: "Villarrica",
        long_name: "Villarrica",
        country: "Chile",
        latitud: -39.2827107,
        longitud: -72.2281654,
        northeast: "-39.27352484793295,-72.2175559749138",
        southwest: "-39.30073791512758,-72.24209236001334",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Viña del Mar",
        long_name: "Viña del Mar",
        country: "Chile",
        latitud: -33.0153481,
        longitud: -71.55002759999999,
        northeast: "-32.94845543653869,-71.47230798146632",
        southwest: "-33.06260243153779,-71.58820312328348",
        polygon: false,
        image_filename: "vina_del_mar",
        search_query_enabled: true,
        home_search_query_enabled: true
    },
    {
        comuna: "Vitacura",
        long_name: "Vitacura",
        country: "Chile",
        latitud: -33.3903016,
        longitud: -70.5722938,
        northeast: "-33.35854128296734,-70.517633124786",
        southwest: "-33.4089482296152,-70.61014092840982",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    // España
    {
        comuna: "Alicante",
        long_name: "Alicante (Alacant)",
        country: "España",
        latitud: 38.3457685,
        longitud: -0.4909444,
        northeast: "38.39093284211179,-0.4034190600039416",
        southwest: "38.32474877136838,-0.5416292294459696",
        polygon: false,
        image_filename: "alicante_alacant",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    // Colombia
    {
        comuna: "Bogotá",
        long_name: "Bogotá",
        country: "Colombia",
        latitud: 4.710988599999999,
        longitud: -74.072092,
        northeast: "4.837131061187439,-73.99197704523905",
        southwest: "4.459464006095289,-74.22363695548903",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: false
    },
    // Barcelona
    {
        comuna: "Barcelona, toda la ciudad",
        long_name: "Barcelona, toda la ciudad",
        country: "España",
        latitud: 41.3873974,
        longitud: 2.168568,
        northeast: "41.4682974272428,2.22804492421789",
        southwest: "41.31703848925413,2.052333262952554",
        polygon: false,
        image_filename: "barcelona_toda_la_ciudad",
        search_query_enabled: true,
        home_search_query_enabled: true
    },
    {
        comuna: "Barcelona, Barrio Gòtico",
        long_name: "Barcelona, Barrio Gòtico",
        country: "España",
        latitud: 41.3819247,
        longitud: 2.1773052,
        northeast: "41.3884704503044,2.18499288523822",
        southwest: "41.37431452544303,2.169963258058956",
        polygon: true,
        image_filename: "barcelona_barrio_gotico",
        search_query_enabled: false
    },
    {
        comuna: "Barcelona, Eixample",
        long_name: "Barcelona, Eixample",
        country: "España",
        latitud: 41.3922586,
        longitud: 2.1650273,
        northeast: "41.41203643993794,2.186895379836445",
        southwest: "41.37497616158768,2.142302787122405",
        polygon: true,
        image_filename: "barcelona_eixample",
        search_query_enabled: true
    },
    {
        comuna: "Barcelona, El Poble-sec",
        long_name: "Barcelona, El Poble-sec",
        country: "España",
        latitud: 41.3725602,
        longitud: 2.1675332,
        northeast: "41.37500872056058,2.176302253314217",
        southwest: "41.36874607379767,2.153527809502968",
        polygon: true,
        image_filename: "barcelona_el_poble_sec",
        search_query_enabled: false
    },
    {
        comuna: "Barcelona, Les Corts",
        long_name: "Barcelona, Les Corts",
        country: "España",
        latitud: 41.38447,
        longitud: 2.1107283,
        northeast: "41.4011141071795,2.14434435000822",
        southwest: "41.3756736761727,2.097791852862739",
        polygon: true,
        image_filename: "barcelona_les_corts",
        search_query_enabled: true
    },
    {
        comuna: "Barcelona, Sant Gervasi-La Bonanova",
        long_name: "Barcelona, Sant Gervasi-La Bonanova",
        country: "España",
        latitud: 41.411538,
        longitud: 2.1323095,
        northeast: "41.41993314975943,2.140734849506438",
        southwest: "41.39934117635848,2.118825718778936",
        polygon: true,
        image_filename: "barcelona_sant_gervasi_la_bonanova",
        search_query_enabled: true
    },
    {
        comuna: "Barcelona, Sant Martí",
        long_name: "Barcelona, Sant Martí",
        country: "España",
        latitud: 41.4100098,
        longitud: 2.2029804,
        northeast: "41.43017620218451,2.22804492421789",
        southwest: "41.38335232726796,2.175467670142995",
        polygon: true,
        image_filename: "barcelona_sant_marti",
        search_query_enabled: false
    },

    {
        comuna: "Bilbao",
        long_name: "Bilbao",
        country: "España",
        latitud: 43.2630126,
        longitud: -2.9349852,
        northeast: "43.28914596491534,-2.889762865922365",
        southwest: "43.23750095589353,-2.977365936169626",
        polygon: false,
        image_filename: "bilbao",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Burgos",
        long_name: "Burgos",
        country: "España",
        latitud: 42.3504659,
        longitud: -3.689354499999999,
        northeast: "42.37213414784006,-3.636750412665408",
        southwest: "42.31611786603707,-3.752635747130636",
        polygon: false,
        image_filename: "burgos",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Cartagena",
        long_name: "Cartagena",
        country: "España",
        latitud: 37.6253022,
        longitud: -0.9972219,
        northeast: "37.63971015232394,-0.9642879391170617",
        southwest: "37.5951130761385,-1.012726866200791",
        polygon: false,
        image_filename: "cartagena",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Granada",
        long_name: "Granada",
        country: "España",
        latitude: 37.1824607,
        longitude: -3.601167599999999,
        northeast: "37.21246481608327,-3.548708401659145",
        southwest: "37.14942766627898,-3.633835119344597",
        polygon: false,
        image_filename: "granada",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Huelva",
        long_name: "Huelva",
        country: "España",
        latitud: 37.2619585,
        longitud: -6.9427327,
        northeast: "37.2913206816194,-6.916788935877258",
        southwest: "37.1,-7.5",
        polygon: false,
        image_filename: "huelva",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Logroño",
        long_name: "Logroño",
        country: "España",
        latitud: 42.4627195,
        longitud: -2.4449852,
        northeast: "42.48168460944743,-2.411083724058472",
        southwest: "42.43890102668015,-2.485641679308714",
        polygon: false,
        image_filename: "logrono",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Lugo",
        long_name: "Lugo",
        country: "España",
        latitud: 43.0097384,
        longitud: -7.5567582,
        northeast: "43.03479856030992,-7.536812134553496",
        southwest: "42.9897686530195,-7.57618807393249",
        polygon: false,
        image_filename: "lugo",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    // Madrid
    {
        comuna: "Madrid, toda la ciudad",
        long_name: "Madrid, toda la ciudad",
        country: "España",
        latitud: 40.4167754,
        longitud: -3.7037902,
        northeast: "40.56384473341083,-3.524911528167741",
        southwest: "40.31206394740597,-3.834161809872056",
        polygon: false,
        image_filename: "madrid_toda_la_ciudad",
        search_query_enabled: true,
        home_search_query_enabled: true
    },
    {
        comuna: "Madrid, Centro",
        long_name: "Madrid, Centro",
        country: "España",
        latitud: 40.4167234,
        longitud: -3.7033851,
        northeast: "40.43061606023851,-3.690288963431012",
        southwest: "40.40504105097957,-3.72287807086027",
        polygon: true,
        image_filename: "madrid_centro",
        search_query_enabled: false
    },
    {
        comuna: "Madrid, Chamartín",
        long_name: "Madrid, Chamartín",
        country: "España",
        latitud: 40.4615174,
        longitud: -3.6865844,
        northeast: "40.48406098931166,-3.660459748722928",
        southwest: "40.43740197218633,-3.691642947174351",
        polygon: true,
        image_filename: "madrid_chamartin",
        search_query_enabled: false
    },
    {
        comuna: "Madrid, Chamberí",
        long_name: "Madrid, Chamberí",
        country: "España",
        latitud: 40.4344286,
        longitud: -3.7131782,
        northeast: "40.44714298465772,-3.688332051908287",
        southwest: "40.42525499283333,-3.719855724175303",
        polygon: true,
        image_filename: "madrid_chamberi",
        search_query_enabled: true
    },
    {
        comuna: "Madrid, Retiro",
        long_name: "Madrid, Retiro",
        country: "España",
        latitud: 40.4113349,
        longitud: -3.6749081,
        northeast: "40.42170803907248,-3.658618015710902",
        southwest: "40.39435987342883,-3.693576918675043",
        polygon: true,
        image_filename: "madrid_retiro",
        search_query_enabled: true
    },
    {
        comuna: "Madrid, Salamanca",
        long_name: "Madrid, Salamanca",
        country: "España",
        latitud: 40.4279488,
        longitud: -3.68675,
        northeast: "40.44464290492917,-3.658618015710902",
        southwest: "40.41863194812891,-3.692713960679216",
        polygon: true,
        image_filename: "madrid_salamanca",
        search_query_enabled: true
    },

    {
        comuna: "Málaga",
        long_name: "Málaga",
        country: "España",
        latitud: 36.721213,
        longitud: -4.4216879,
        northeast: "36.75755262609146,-4.339496540648535",
        southwest: "36.67889138816003,-4.559037314171312",
        polygon: false,
        image_filename: "malaga",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Oviedo",
        long_name: "Oviedo",
        country: "España",
        latitud: 43.3622522,
        longitud: -5.8485461,
        northeast: "43.39203855924637,-5.812127239770163",
        southwest: "43.34693506623958,-5.885188444013071",
        polygon: false,
        image_filename: "oviedo",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Pamplona",
        long_name: "Pamplona",
        country: "España",
        latitud: 42.812526,
        longitud: -1.6457745,
        northeast: "42.83811756569991,-1.604659160572587",
        southwest: "42.79341437009327,-1.695384048112043",
        polygon: false,
        image_filename: "pamplona",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Salamanca",
        long_name: "Salamanca",
        country: "España",
        latitud: 40.9701039,
        longitud: -5.663539699999999,
        northeast: "40.98509709418802,-5.631203931940572",
        southwest: "40.94177994596159,-5.707220262223757",
        polygon: false,
        image_filename: "salamanca",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "San Sebastián",
        long_name: "San Sebastián",
        country: "España",
        latitud: 43.318334,
        longitud: -1.9812313,
        northeast: "43.32871430102026,-1.924211465519547",
        southwest: "43.2858710377457,-2.023554449750387",
        polygon: false,
        image_filename: "san_sebastian",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Santander",
        long_name: "Santander",
        country: "España",
        latitud: 43.4636346,
        longitud: -3.8226402,
        northeast: "43.48639082374222,-3.753610862903355",
        southwest: "43.43629802556797,-3.868663249176218",
        polygon: false,
        image_filename: "santander",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Segovia",
        long_name: "Segovia",
        country: "España",
        latitude: 40.9429032,
        longitude: -4.108806899999999,
        northeast: "40.96250166650696,-4.090817022707955",
        southwest: "40.91999864796654,-4.133586824536152",
        polygon: true,
        image_filename: "segovia",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Sevilla",
        long_name: "Sevilla",
        country: "España",
        latitud: 37.3890924,
        longitud: -5.9844589,
        northeast: "37.43552116934161,-5.888458652078246",
        southwest: "37.31522026882898,-6.021657814478962",
        polygon: false,
        image_filename: "sevilla",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    // Valencia
    {
        comuna: "Valencia, toda la ciudad",
        long_name: "Valencia, toda la ciudad",
        country: "España",
        latitud: 39.4699075,
        longitud: -0.3762881,
        northeast: "39.5073224594516,-0.2914778120622837",
        southwest: "39.30824799722067,-0.4315448083676902",
        polygon: false,
        image_filename: "valencia_toda_la_ciudad",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Valencia, Ayora",
        long_name: "Valencia, Ayora",
        country: "España",
        latitud: 39.4665282,
        longitud: -0.3430688,
        northeast: "39.46972199221864,-0.3353839679034252",
        southwest: "39.46176506550147,-0.35129801636527",
        polygon: true,
        image_filename: "valencia_ayora",
        search_query_enabled: false
    },
    {
        comuna: "Valencia, Camins al Grau",
        long_name: "Valencia, Camins al Grau",
        country: "España",
        latitud: 39.4626283,
        longitud: -0.3489496,
        northeast: "39.47147698763202,-0.3353839679034252",
        southwest: "39.45377305841663,-0.3609381645184877",
        polygon: true,
        image_filename: "valencia_camins_al_grau",
        search_query_enabled: false
    },
    {
        comuna: "Valencia, Quatre Carreres",
        long_name: "Valencia, Quatre Carreres",
        country: "España",
        latitud: 39.44964909999999,
        longitud: -0.3607137,
        northeast: "39.46314689388979,-0.3345368354468051",
        southwest: "39.42602901665821,-0.3868677389683054",
        polygon: true,
        image_filename: "valencia_quatre_carreres",
        search_query_enabled: false
    },
    {
        comuna: "Valencia, Poblats Maritims",
        long_name: "Valencia, Poblats Maritims",
        country: "España",
        latitud: 39.45232850000001,
        longitud: -0.3301342,
        northeast: "39.48589403033223,-0.302151860409177",
        southwest: "39.42277194247347,-0.3416658356009217",
        polygon: true,
        image_filename: "valencia_poblats_maritims",
        search_query_enabled: false
    },
    {
        comuna: "Valladolid",
        long_name: "Valladolid",
        country: "España",
        latitude: 41.6543639,
        longitude: -4.722273299999999,
        northeast: "41.81550857174353,-4.689443915306096",
        southwest: "41.5909982535988,-4.928180310147945",
        polygon: true,
        image_filename: "valladolid",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    // México
    {
        comuna: "D.F. México",
        long_name: "D.F. México",
        country: "México",
        latitud: 19.2464696,
        longitud: -99.1013498,
        northeast: "19.59275714055156,-98.94030274603077",
        southwest: "19.04823658558244,-99.36492415078024",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: false
    },
    // Panamá
    {
        comuna: "Ciudad de Panamá",
        long_name: "Ciudad de Panamá",
        country: "Panamá",
        latitud: 8.9823792,
        longitud: -79.51986959999999,
        northeast: "9.259696609737304,-79.30689406194774",
        southwest: "8.90313381534501,-79.59938042427454",
        polygon: false,
        image_filename: "default",
        search_query_enabled: false,
        home_search_query_enabled: false
    },
    // Perú
    {
        comuna: "Lima",
        long_name: "Lima",
        country: "Perú",
        latitud: -12.0463731,
        longitud: -77.042754,
        northeast: "-11.79998745006646,-76.78833965163773",
        southwest: "-12.25328905340461,-77.18721856505658",
        polygon: false,
        image_filename: "lima",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    // Portugal
    {
        comuna: "Braga",
        long_name: "Braga",
        country: "Portugal",
        latitud: 41.5454486,
        longitud: -8.426506999999999,
        northeast: "41.58414768853574,-8.342179110418417",
        southwest: "41.51040203529377,-8.480663286579226",
        polygon: false,
        image_filename: "braga",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Covilhã",
        long_name: "Covilhã",
        country: "Portugal",
        latitud: 40.2805633,
        longitud: -7.504327199999999,
        northeast: "40.31497336750819,-7.464640958209947",
        southwest: "40.256491553217,-7.537834777061322",
        polygon: false,
        image_filename: "covilha",
        search_query_enabled: false,
        home_search_query_enabled: true
    },
    {
        comuna: "Oporto",
        long_name: "Oporto",
        country: "Portugal",
        latitud: 41.1579438,
        longitud: -8.629105299999999,
        northeast: "41.18602848569317,-8.552613373962807",
        southwest: "41.13835060908927,-8.691293920826338",
        polygon: false,
        image_filename: "oporto",
        search_query_enabled: false,
        home_search_query_enabled: true
    }
];
