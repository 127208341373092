<template>
    <section class="benefits-section">
        <div class="container d-flex">
            
            <!-- Column left -->
            <div class="column-left col-12 col-md-6">
                <h2 class="section-title">{{ $t("benefits.title") }}</h2>
                <p class="section-description">{{ $t("benefits.subtitle") }}</p>
            </div>

            <!-- Column right -->
            <div class="column-right col-12 col-md-6">
                <div class="benefits-list">
                    <div
                        v-for="(benefit, index) in benefits"
                        :key="index"
                        class="benefit-item"
                    >
                        <div class="icon-container">
                            <img :src="require(`@/new-assets/icons/${benefit.icon}`)" :alt="benefit.titleKey" class="icon" />
                        </div>
                        <div class="content">
                            <span class="benefit-number">{{ index + 1 }}</span>
                            <h3 class="benefit-title">{{ $t(benefit.titleKey) }}</h3>
                            <p class="benefit-description">{{ $t(benefit.descriptionKey) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script>
export default {
    name: "BenefitsSection",
    data() {
        return {
            benefits: [
                {
                    icon: "car.svg",
                    titleKey: "benefits.transport_title",
                    descriptionKey: "benefits.transport_description",
                },
                {
                    icon: "gift.svg",
                    titleKey: "benefits.welcome_kit_title",
                    descriptionKey: "benefits.welcome_kit_description",
                },
                {
                    icon: "user.svg",
                    titleKey: "benefits.community_title",
                    descriptionKey: "benefits.community_description",
                },
                {
                    icon: "discount.svg",
                    titleKey: "benefits.discounts_title",
                    descriptionKey: "benefits.discounts_description",
                },
                {
                    icon: "new-card.svg",
                    titleKey: "benefits.flexible_payments_title",
                    descriptionKey: "benefits.flexible_payments_description",
                },
                {
                    icon: "info.svg",
                    titleKey: "benefits.personalized_support_title",
                    descriptionKey: "benefits.personalized_support_description",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>

.benefits-section {
    padding: 100px 20px;
    background: url('~@/new-assets/images/decor-8.svg') no-repeat 0px 50px;
    background-size: contain;
}

.section-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: $hf-primary;
}

.section-description {
    font-size: 2.3rem;
    color: $h2;
}

.benefits-list {
}

.benefit-item {
    display: flex;
    padding: 20px;
    transition: transform 0.3s ease;
    position: relative;
}

.benefit-item:hover {
    transform: translateY(-5px);
}

.icon-container {
    padding: 30px 30px 0 10px;
}

.icon {
    width: 25px;
    height: 25px;
}

.content {
}

.benefit-number {
    position: absolute;
    left: 10px;
    background-color: $grey-75;
    color: #fff;
    font-size: 0.8rem;
    width: 20px;
    height: 20px;
    display: block;
    text-align: center;
    border-radius: 50%;
}

.benefit-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: $h3;
}

.benefit-description {
    font-size: 1rem;
    color: $grey-50;
}

@media (max-width: $md) {

    .benefits-section {
        padding: 20px;
        background: none;
    }
    
    .container {
        flex-direction: column;
        padding: 0;
    }
    
    .column-left, .column-right {
        padding: 0;
    }
    
    .section-description {
        font-size: 1.5rem;
    }

    .benefits-list {
        height: 200px;
        display: flex;
        overflow-x: scroll;
        height: auto;
        scroll-behavior: smooth;
    }

    .benefit-item {
        flex: 0 0 auto;
        width: calc(100% - 70px);
        flex-direction: column;
    }
    
    .icon-container {
        text-align: center;
        padding-bottom: 50px;
    }
    
    .content {
        display: flex;
        flex-direction: column;
    }
    
    .benefit-number {
        left: 34%;
        top: 25px;
    }

    .benefit-title {
        text-align: center;
    }
}

</style>
