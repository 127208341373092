<template>
	<b-carousel
		v-if="loaded"
		indicators
		controls
        no-animation
        class="carousel"
    >
			<b-carousel-slide
				v-for="photo in photos"
				:key="photo.idgaleria"
                class="carousel-item"
			>
				<template #img>
					<img
						class="carousel-img"
						:src="photo.url.indexOf('http') > -1 ? photo.url : url + photo.url"
						:id="photo.idgaleria"
					/>
				</template>
			</b-carousel-slide>
	</b-carousel>
</template>

<script>
import axios from "axios";
export default {
	name: "CarouselList",
    props: {
        idAlojamiento: {},
        externalReference: {},
        photos: {
            type: Array,
            default: () => []
        }
    },
	data() {
		return {
			url: process.env.VUE_APP_S3_URL,
			loaded: false,
		};
	},
	created() {

        // if this.photos is undefined or aen empty array then fetch photos from the API
        if(this.photos.length === 0) {
            axios({
                url:
                    process.env.VUE_APP_API_URL +
                    "galleries/searchByAccommodation/" +
                    this.idAlojamiento,
                method: "GET",
            }).then((resp) => {
                for (let i = 0; i < resp.data.photos.length; i++) {
                    this.photos.push(resp.data.photos[i]);
                }
            });   
        }
        this.loaded = true;
    },
    
};
</script>

<style lang="scss" scoped>

.carousel {

    aspect-ratio: 4/3;
    overflow: hidden;

    .carousel-item {

        .carousel-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

</style>