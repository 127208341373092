<template>
  <button :class="['new-btn', `btn--${variant}`]" @click="onClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    variant: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    }
  }
}
</script>