<template>
	<div>
        <PicturesPopUp />
		<div class="top-info">
			{{ $t('wizard.s3.need-to-see') }}
		</div>
		<hr />

        <!-- Botón para agregar fotos -->
        <div class="text-center">
            <Button class="new-btn btn--thick my-4 add-button" variant="primary" @click="triggerFileInput" :disabled="previewImages.length >= 20">
                <i class="fas fa-plus-circle mr-2" />
                <span class="font-weight-bold">
                    Agregar fotos
                </span>
            </Button>
            
            <input 
                type="file" 
                multiple 
                ref="fileInput" 
                @change="onFileChange" 
                style="display: none;"
                accept="image/png, image/jpeg, image/jpg"
            />

        </div>

        <!-- Mensaje de validación -->
        <div class="text-danger mb-3 text-center font-weight-bold" v-if="previewImages.length < 4">
            {{ $t('wizard.s3.min-pictures') }}
        </div>
        <div class="text-danger mb-3 text-center font-weight-bold" v-if="previewImages.length >= 20">
            {{ $t('wizard.s3.max-pictures') }}
        </div>


        <!-- Vista previa de imágenes -->
        <div class="row">
            <div
                class="col-xs-12 col-sm-6 col-md-3 picture-container"
                v-for="(image, index) in previewImages"
                :key="index"
            >
                <!-- Remove icon -->
                <span class="close-pic" @click="removeImage(index)">
                    <i class="fas fa-times" />
                </span>

                <!-- Picture preview -->
                <div class="picture-inner">
                    <img class="image-preview" :src="image" />
                </div>

            </div>
        </div>

		<FooterWizard prev next :validate="previewImages.length < 4" />
	</div>
</template>

<script>
import FooterWizard from "./FooterWizard";
import PicturesPopUp from '../PopUps/PicturesPopUp';
import Button from "@/components/Ui/Button.vue";
import axios from 'axios';

export default {
	name: "Step3Wizard",
	components: {
        FooterWizard,
        PicturesPopUp,
        Button
	},
    props: {
        data: {
            type: Array,
            default: null
        }
    },
	data() {
		return {
            url: process.env.VUE_APP_S3_URL,
			selectedFiles: [], // Almacena los archivos seleccionados
			previewImages: [], // Vista previa de imágenes
            preview: {}, // Almacena las imágenes en base64
            accommodation: {
                fotos: {}
            },
		};
	},
    created() {
        if (this.data != null) {
            // this.accommodation.fotos = this.data.map(picture => this.url + picture.url);
            this.previewImages = this.data.map(picture => this.url + picture.url);
            
            // // Add existing pictures to the accommodation object
            this.accommodation.fotos = this.data.map(picture => {
                return {
                    type: 'existing',
                    url: picture.url,
                };
            });
        }
    },

	methods: {

        // Trigger the file input
        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        // Pictures selection
        onFileChange(event) {
            const files = event.target.files;
            const remainingSlots = 20 - this.previewImages.length;

            for (let i = 0; i < Math.min(files.length, remainingSlots); i++) {
                
                let file = files[i];
                this.selectedFiles[i] = file;

                // Create a new FileReader object
                let fileReader = new FileReader();
                
                // Set the onload event. This event is triggered each time the reading operation is successfully completed.
                fileReader.onload = (e) => {
                    this.previewImages.push(e.target.result);

                };

                // Do the reading (this will trigger the onload event)
                fileReader.readAsDataURL(file);

                let existingPictures = this.previewImages.length;

                // Add the file to the accommodation object
                this.$set(this.accommodation.fotos, existingPictures + i, file);

            }
              
        },

        // Remove an image from the preview and the accommodation object
        removeImage(index) {
            const keys = Object.keys(this.previewImages);
            const key = keys[index];
            this.$delete(this.previewImages, key);
            this.$delete(this.accommodation.fotos, key);
        },

        nextStep() {
			this.$emit("next", this.accommodation);
		},
		prevStep() {
			this.$emit("prev");
		},
        
	}
};
</script>


<style lang="scss" scoped>

@import "@/new-assets/styles";

.btn-outline-primary {
	background-color: #fff;
	border-color: $hf-sec-pine !important;
	color: $hf-sec-pine;
	&:hover,
	&:active,
	&:focus {
		color: #fff;
		background-color: $hf-sec-pine !important;
		border-color: $hf-sec-pine;
	}
}
.top-info {
	padding-top: 10px;
}

.row {
    min-height: 37vh;
    align-content: flex-start;
}

.picture-container {
	padding: 10px;

	.picture-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 100%;
        overflow: hidden;
        border: 1px dashed $grey-50;
        border-radius: 15px;
        padding: 10px;
    }


    .image-preview {
        max-width: 100%;
        max-height: 100%;
    }

	.close-pic {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 15px;
		border: 2px solid red;
		padding: 4px;
		border-radius: 50%;
		top: 15px;
		color: red;
		z-index: 100;
		background: #fff;
		cursor: pointer;
		width: 30px;
		height: 30px;
		text-align: center;
	}
}

.picture-info {
	display: flex;
	border: dashed $hf-sec-pine 2px;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 100%;
	padding: 10px 20px;
	text-align: center;

    img {
        max-width: 100px;
        fill: $hf-sec-pine;
    }
}


label {
	width: 100%;
}

.add-button {
    width: 100%;
    @media (min-width: 768px) {
        width: auto;
        
    }
}
</style>