<template>
    <div class="full-width-bg">
        <div class="container">

            <!-- Title -->
            <div class="contact-form-title">{{$t('contact-form.title')}}</div>

            <!-- Subtitle -->
            <div class="contact-form-subtitle">{{$t('contact-form.subtitle')}}</div>

            <form class="contact-form" @submit.prevent="">

                <!-- First name -->
                <ValidatedInput
                    fieldName="first_name"
                    v-model="formData.first_name"
                    :label="$t('contact-form.first-name')"
                    :placeholder="$t('contact-form.first-name-placeholder')"
                    :validationObject="$v.formData.first_name"
                />

                <!-- Last name -->
                <ValidatedInput
                    fieldName="last_name"
                    v-model="formData.last_name"
                    :label="$t('contact-form.last-name')"
                    :placeholder="$t('contact-form.last-name-placeholder')"
                    :validationObject="$v.formData.last_name"
                />

                <!-- Email -->
                <ValidatedInput
                    fieldName="email"
                    v-model="formData.email"
                    :label="$t('contact-form.email')"
                    :placeholder="$t('contact-form.email-placeholder')"
                    :validationObject="$v.formData.email"
                />

                <!-- Message -->
                <ValidatedTextArea
                    fieldName="message"
                    v-model="formData.message"
                    :label="$t('contact-form.message')"
                    :placeholder="$t('contact-form.message-placeholder')"
                    :validationObject="$v.formData.message"
                />

                <!-- Captcha -->
                <!-- TODO: captcha-->
                

                <!-- Send button -->
                <Button variant="primary" type="submit" class="w-100" @click="handleSubmit">
                    {{ $t('contact-form.send') }}
                </Button>

                <!-- Sent message -->
                <span v-if="sent" class="sent-message">{{ $t('contact-form.sent-message')}}</span>                
                
            </form>
            
        </div>
        
    </div>
</template>

<script>
import axios from 'axios';
import Button from "@/components/Ui/Button.vue";
import ValidatedInput from "@/components/ValidatedFields/ValidatedInput.vue";
import {validations} from "@/components/ValidatedFields/contactFormRules";
import ValidatedTextArea from "@/components/ValidatedFields/ValidatedTextArea.vue";
import apiUrls from "@/new-constants/apiUrls";

export default {
    name: "ContactForm",
    components: {
        ValidatedTextArea,
        ValidatedInput,
        Button
    },
    data() {
        return {
            formData: {
                first_name: '',
                last_name: '',
                email: '',
                message: '',
            },
            sent: false
        }
    },
    validations: validations,
    methods: {
        async handleSubmit() {
            // Validate the form
            this.$v.$touch();
            
            // If the form is invalid, do not submit
            if (this.$v.$invalid) {
                return;
            }

            // If the validation is successful, send the data
            try {
                await this.sendContactForm();
                this.sent = true;
            } catch (error) {
                console.error(error);
            }
            
        },

        async sendContactForm() {
            console.log('sendContactForm');
            try {
                const response = await axios.post(apiUrls.contactForm, this.formData);
                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }
    },
    computed: {
    },
    created() {
    },
    watch: {
        // Reset sent flag when a field change
        formData: {
            handler() {
                this.sent = false;
            },
            deep: true
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/new-assets/styles";

.full-width-bg {
    width: 100%;
    background-color: $grey-backgroud;
}

.container {
    background-color: $grey-backgroud;
    padding: 50px 0;
}

.contact-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.contact-form-title {
    padding: 20px 0 0;
    font-size: 2.3rem;
    color: $h2;
    text-align: center;
}

.contact-form-subtitle {
    padding: 20px 0;
    font-size: 1.2rem;
    color: $grey-50;
    text-align: center;
}

.sent-message {
    padding: 0px 0;
    font-size: 1.1rem;
    color: $hf-office;
    text-align: center;
}

@media (max-width: $md) {
    .container {
        padding: 20px 0;
    }

    .contact-form {
        padding: 0 20px;
        width: 100%;
    }

    .contact-form-title {
        font-size: 1.5rem;
    }
    
    .contact-form-subtitle {
        font-size: 1.2rem;
    }
}
</style>