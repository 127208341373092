<template>
    <section class="blog-section">
        <div class="blog container">

            <h2 class="section-title">{{ $t("blog.title") }}</h2>
            <p class="section-description">{{ $t("blog.subtitle") }}</p>
                
            <ul class="d-flex overflow-auto">
                <li v-for="(entry, index) in localizedBlogData" :key="index" class="blog-entry">
                    <a :href="entry.url" target="_self" class="blog-link">
                        <img :src="require(`@/new-assets/images/${entry.image}`)" :alt="entry.title" class="blog-image" />
                        <span class="blog-title">{{ entry.title }}</span>
                        <div class="more">
                            {{ $t("blog.more")}}
                            <i class="icon" />
                        </div>
                    </a>
                </li>
            </ul>
            
            <!-- Goto blog button -->
            <div class="button-wrapper">
                <a  href="https://houseandflats.com/blog/" class="new-btn btn--primary">
                    {{ $t('blog.go-to-blog') }}
                </a>
            </div>
            
        </div>
    </section>
</template>

<script>
import blogData from "@/components/Blog/blogEntries.json";
import Button from "@/components/Ui/Button.vue";

export default {
    name: "Blog",
    components: {Button},
    data() {
        return {
            blogData,
        };
    },
    computed: {
        localizedBlogData() {
            return this.blogData.map(entry => ({
                ...entry,
                title: entry.title[this.$i18n.locale],
            }));
        },
    }
};
</script>



<style lang="scss" scoped>
@import "@/new-assets/styles";

.blog {

    .benefits-section {
        padding: 100px 20px;
        background: url('~@/new-assets/images/decor-8.svg') no-repeat 0px 50px;
        background-size: contain;
    }

    .section-title {
        font-size: 1.2rem;
        font-weight: bold;
        color: $hf-primary;
        padding: 30px 0 0 15px;
    }

    .section-description {
        padding: 0px 15px 10px 15px;
        font-size: 2.3rem;
        color: $h1;
        
    }

    ul {
        margin-top: 15px;
        display: flex;
        justify-content: left;
        padding: 6px;
        gap: 20px;
        list-style: none;
        overflow-x: auto;

        li {
            height: 434px;
            flex: 0 0 413px;
            position: relative;
            overflow: hidden;
            transition: transform 0.3s ease;

            &:hover {
                transform: translateY(-5px); /* Hover animation */
            }

            .blog-link {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 100%;
                color: white;
                padding: 0 30px 30px 30px;
            }

            .blog-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensure the image covers the entire space */
                z-index: 1;
                transition: opacity 0.3s ease; /* Add transition for hover effect */
                border-radius: $border-radius-new;
            }
            

            .blog-title {
                z-index: 2;
                font-size: 1.5rem;
                color: #ffffff;
                font-weight: bold;
                text-align: left;
            }
            
            .more {
                margin-top: 20px;
                z-index: 2;
                font-size: 1rem;
                border: 2px solid #ffffff;
                border-radius: $border-radius-new;
                width: auto;
                align-self: flex-start;
                padding: 6px 15px;

                .icon {
                    display: inline-block;
                    background-image: url("~@/new-assets/icons/arrow-right.svg");
                    width: 16px;
                    height: 16px;
                    margin-left: 8px;
                }
            }
        }
    }
    
    .button-wrapper {
        text-align: center;
        padding: 10px 0 80px 0;
    }

    /* Media Query for mobile view to stack images vertically */
    @media (max-width: $md) {

        .section-title {
            padding: 20px 0 0 5px;
        }
        .section-description {
            padding: 0px 0 0 5px;
            font-size: 1.5rem;
            //color: $h2;
        }

        ul {
            gap: 10px;
            height: auto;
            scroll-behavior: smooth;
            padding: 6px;
            margin: 5px 0;
        }

        li {
            height: 375px !important;
            flex: 0 0 270px !important;
            
        }
        
        .blog-title {
            font-size: 1rem !important;
        }
    }
}
</style>
