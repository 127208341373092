<template>
    <div class="full-width-bg">
        <div class="container row">
            <div class="col-12 col-md-6 order-last order-md-first">

                <div class="why-title">¿Por qué House & Flats?</div>

                <div class="why-content">
                    <p v-html="$t('why.content-1')" />
                    <p v-html="$t('why.content-2')" />
                    <p v-html="$t('why.content-3')" />
                </div>
    
                <ButtonLikeLink :to="{ name: 'contact' }" variant="primary" class="contact-us">
                    {{ $t('why.button-text') }}
                </ButtonLikeLink>
                
            </div>
            <div class="col-12 col-md-6 order-first order-md-last">
                <img src="@/new-assets/images/why.png" class="w-100" alt="Why House & Flats">
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Ui/Button.vue";
import ButtonLikeLink from "@/components/Ui/ButtonLikeLink.vue";

export default {
    name: "Why",
    components: {
        ButtonLikeLink,
        Button
    },
};
</script>

<style scoped lang="scss">
@import "@/new-assets/styles";

.full-width-bg {
    width: 100%;
    background-color: $grey-backgroud;
}

.container {
    margin: auto;
    padding: 50px 0 50px 0;
}

.why-title {
    padding: 30px 15px 10px 15px;
    font-size: 2.3rem;
    color: $h2;
}

.why-content {
    padding: 20px 15px;
    font-size: 1.2rem;
    color: $grey-50;
}

.contact-us {
    margin-left: 15px;
}

@media (max-width: $md) {
    .why-title {
        font-size: 1.5rem;
    }
    .why-content {
        padding: 0px 15px;
        font-size: 1rem;
    }
}

</style>