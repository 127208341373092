<template>

    <!-- mmenu -->
    <nav id="menu">
        <ul>

            <!-- Admin -->
            <li v-if="isLoggedIn && user.is_admin === 1">
                <router-link :to="{ name: 'admin' }">{{ $t('main-menu.admin') }}</router-link>
            </li>

            <!-- Sign in -->
            <li v-if="!isLoggedIn" class="highlight">
                <router-link :to="{ name: 'login' }">{{ $t('main-menu.sign-in') }}</router-link>
            </li>

            <!-- Sign up -->
            <li v-if="!isLoggedIn" class="highlight">
                <router-link :to="{ name: 'register', params: { step: 1 } }">{{ $t('main-menu.sign-up') }}</router-link>
            </li>
            
            <!-- Contact us -->
            <li class="highlight">
                <router-link :to="{ name: 'conversation' }">{{ $t('side-menu.messages') }}</router-link>
            </li>
            
            <!-- Home -->
            <li>
                <router-link :to="{ name: 'home' }">{{ $t('main-menu.home') }}</router-link>
            </li>
            
            <!-- Contact us -->
            <li v-if="isLoggedIn">
                <span>{{ $t('main-menu.my-profile') }}</span>
                <ul>
                    <li>
                        <router-link :to="{ name: 'my-accommodations' }">{{ $t('main-menu.my-accommodations') }}</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'reservations-made' }" class="hola">{{ $t('main-menu.reservations-made') }}</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'reservations-received' }">{{ $t('main-menu.reservations-received') }}</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'favoritos' }">{{ $t('main-menu.my-favorites') }}</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'editar-perfil' }">{{ $t('main-menu.edit-profile') }}</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'Cambiar Password' }">{{ $t('main-menu.change-password') }}</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'Datos bancarios' }">{{ $t('main-menu.bank-information') }}</router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'ical' }">{{ $t('main-menu.icalendar') }}</router-link>
                    </li>
                </ul>
            </li>
            
            <!-- About us -->
            <li>
                <router-link :to="{ name: 'about' }">{{ $t('main-menu.about') }}</router-link>
            </li>
            
            <!-- Blog -->
            <li>
                <a href="https://houseandflats.com/blog/">Blog</a>
            </li>
            
            <!-- Sign out -->
            <li v-if="isLoggedIn">
                <a @click="logout">{{ $t('dashboard-side-menu.sign-out') }}</a>
            </li>
            
            <!-- Switchers -->
            <div class="switchers">
                <CurrencySwitcher />
                <LanguageSwitcher />
            </div>
            
        </ul>
    </nav>

</template>

<script>
import {mapActions, mapState} from "vuex";
// import menuData from "./menu-data.json";
import {mapGetters} from "vuex";
import CurrencySwitcher from "@/components/Header/CurrencySwitcher.vue";
import LanguageSwitcher from "@/components/Header/LanguageSwitcher.vue";

export default {
    name: "Sidebar",
    components: {LanguageSwitcher, CurrencySwitcher},
    props: {
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            // menuData,
            api: null
        };
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["isLoggedIn"]),
    },
    methods: {
        ...mapActions(["logout"]),
        closeMenu() {
            this.api.close();
            this.$emit('close');
        },
        openMenu() {
            this.api.open();
        }
    },
    watch: {
        isOpen(newValue) {
            if (newValue) {
                this.openMenu();
            }
        }
    },
    mounted() {
        const menu = new Mmenu("#menu", {
            // Options
            offCanvas: {
                position: "right-front",
            },
            navbar: {
                add: true,
                title: " ",
            },
            theme: "white",
        },{
            // Configuration
            offCanvas: {
                page: {
                    selector: "#page",
                    noSelector: ["#whatsapp-chat"],
                },
            },
        });

        this.api = menu.API;
        
        // Add a listener to close the menu when an option is selected
        document.querySelectorAll("#menu a:not(.mm-btn):not(.mm-navbar__title)").forEach((link) => {
            link.addEventListener("click", () => {
                this.closeMenu();
            });
        });

        // Add a listener to close the menu when the user clicks outside of it
        document.querySelector(".mm-blocker").addEventListener("click", () => {
            this.closeMenu();
        });
        
    }
};
</script>


<style lang="scss" scoped>

ul > li > a {
    padding: 20px;
}

::v-deep .mm-navbar__title {
    color: $grey-75 !important;
    font-weight: bold;
    justify-content: flex-start;
}

.highlight {
    color: $hf-sec-pine;
    font-weight: bold;
}

.switchers {
    display: flex;
    gap: 20px;
    padding: 20px;
}

</style>
