<template>
    <div>

        <div :class="[ {'disable-datepicker': noDateDefined} ]">
            <HotelDatePicker
                @check-in-changed="checkInChanged($event)"
                @check-out-changed="checkOutChanged($event)"
                @click.native="addSeeNextMonthButton"
                :format="dateFormat"
                :showYear="true"
                :i18n="dictionary"
                :startingDateValue="defaultDate(checkInDate)"
                :endingDateValue="defaultDate(checkOutDate)"
                :disabledDates="disabledDates"
                :minNights="minNights"
                type='button'
                ref='datePicker'
                class="date-picker"
            ></HotelDatePicker>
        </div>

        <!-- Undefined date checkbox -->
        <div v-if="showAnyDateCheckbox" class="date-check align-self-center">
            <div class="checkbox-wrapper">
                <input type="checkbox" class="check" autocomplete="off" :checked="noDateDefined" @change="noDateDefined = !noDateDefined">    
            </div>
            <label class="label" @click="noDateDefined = !noDateDefined">{{ $t('search.date-not-defined') }}</label>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import HotelDatePicker from 'vue-hotel-datepicker';
import { mapState, mapActions } from 'vuex';

export default {
    
    name: 'DatePicker',
    
    components: {
        HotelDatePicker
    },
    
    props: {
        disabledDates: {
            type: Array,
            default: () => []
        },
        minNights: {
            type: Number,
            default: 1
        },
        showAnyDateCheckbox: {
            type: Boolean,
            default: false
        }
    },
    
    data() {
        return {
            
            noDateDefined: false,
            
            dateFormatEn: "YYYY/MM/DD",
            dateFormatEs: "DD/MM/YYYY",
            
            spanish: {
                night: "Noche",
                nights: "Noches",
                "day-names": ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "SaB"],
                "check-in": "Ingreso",
                "check-out": "Salida",
                "month-names": [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre"
                ]
            },
            
            english: {
                night: "Night",
                nights: "Nights",
                "day-names": ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
                "check-in": "Check-in",
                "check-out": "Check-Out",
                "month-names": [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ]
            },
        }
    },

    created() {
        // Sometimes the dates are passed as query params, sometimes as route params. TODO; unify this
        let fecha_inicio = this.$route.params.fecha_inicio || this.$route.query.fecha_inicio;
        let fecha_termino = this.$route.params.fecha_termino || this.$route.query.fecha_termino;
        this.setCheckInDate(fecha_inicio);
        this.setInitDate(fecha_inicio);
        this.setCheckOutDate(fecha_termino);
        this.setEndDate(fecha_termino);
    },
    
    computed: {
        
        ...mapState('search', [
            "checkInDate",
            "checkOutDate",
        ]),

        dictionary() {
            return this.$i18n.locale === "es" ? this.spanish : this.english;
        },
        
        dateFormat() {
            return (this.$i18n.locale === "es") ? this.dateFormatEs : this.dateFormatEn;
        }
        
    },
    methods: {

        ...mapActions("search", [
            "setCheckInDate",
            "setCheckOutDate",
        ]),

        // The dates could be used for a search, a simulation, or to maka a reservation
        ...mapActions("simulator", [
            'setInitDate',
            'setEndDate',
        ]),
        
        checkInChanged(date) {
            this.setCheckInDate(date);
            this.setInitDate(date);
        },
        
        checkOutChanged(date) {
            this.setCheckOutDate(date);
            this.setEndDate(date);
        },

        /**
         * The datepicker requires a date object, so we need to convert
         * the date string to a date object} date
         */
        defaultDate(date) {
            let tempDate =  (date === null) ? null : moment(date).utcOffset(0).toDate();
            return tempDate;
        },

        addSeeNextMonthButton() {
            if (this.$refs.datePicker) {
                let text = this.$i18n.locale === "es" ? "Siguiente mes" : "Next month";
                let seeMore = document.querySelector(".next--mobile");
                if (seeMore) {
                    seeMore.textContent = text;
                }
                // Scroll calendar bottom smooth
                let calendarContainer = document.querySelector(".datepicker__months");
                if (calendarContainer) {
                    calendarContainer.scrollBy({
                        top: calendarContainer.scrollHeight,
                        left: 0,
                        behavior: "smooth",
                    });
                }

            }
        },

       
    }
}
</script>

<style lang="scss" scoped>

.datepicker {
    z-index: 1021 !important;
}

//::v-deep .datepicker__wrapper {
//    z-index: 10000; /* O un valor suficientemente alto */
//    position: relative; /* o absolute/fixed si es necesario */
//}

.datepicker__wrapper {
    background-image: none;
}
::v-deep .datepicker__dummy-wrapper {
    border-radius: $border-radius-new;
}

::v-deep .datepicker__input[data-qa="datepickerInput"] {
    display: flex;
    align-items: center;
    text-indent: 0;
    padding: 18px !important;
    
}
::v-deep .datepicker__input[data-qa="datepickerInput"]::before {
    content: '';
    width: 22px;
    height: 24px;
    background-image: url("~@/new-assets/icons/home-enter.svg");
    background-repeat: no-repeat;
    margin-right: 7px;
}
::v-deep .datepicker__input[data-qa="datepickerInput"]:first-child::before {
    padding: 10px !important;
}
::v-deep .datepicker__input[data-qa="datepickerInput"]:last-child::before {
    background-image: url("~@/new-assets/icons/home-exit.svg");
}
::v-deep .datepicker__clear-button {
    padding: 8px 11px;
}

::v-deep .disable-datepicker {
    pointer-events: none;
    opacity: 0.5;
}

.date-check {

    display: flex;
    margin: 0 12px;
    height: 56px;
    text-align: left;
    align-items: center;

    .checkbox-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $grey-10;
        border-radius: 30%;
    }
    
    .check {
        width: 20px;
        height: 20px;
        border-radius: 30%;
        border: 3px solid #ffffff;
        appearance: none;
        -webkit-appearance: none;
        cursor: pointer;
    }

    .check:checked {
        background-color: $hf-primary;
    }


    .label {
        color: $grey-75;
        margin-left: 10px;
        margin-top: 6px;
        
    }
}

// Next month button
.next--mobile {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    background: $hf-sec-pine;
    height: $see-more-button-height;
    color: #FFFFFF;
    font-size: 1.2rem;
    font-style: italic;
    z-index: 1021 !important;
}

.date-picker {
    padding-top: 6px;
    height: 50px!important
    
}

.date-picker > div {
    border-radius: $border-radius;
}

</style>