import {hreflangKeywords, supportedHrefLanguages} from "@/lang/hreflangKeywords";
import { supportedLanguages } from '@/config';
import {locationsList} from "@/new-constants/locationsList";
import {normalizeUrl} from "@/Utils/Utils";

// Helper function to create and append <link> tags if they do not already exist
function createLinkTag(rel, hreflang, href) {
    if (!document.querySelector(`link[hreflang="${hreflang}"]`)) {
        const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', rel);
        if (hreflang) linkTag.setAttribute('hreflang', hreflang); // Avoid adding hreflang for canonical tags
        linkTag.setAttribute('href', normalizeUrl(href));
        document.head.appendChild(linkTag);
    }
}

export function addHreflangTags(currentUrl) {
    const baseUrl = process.env.VUE_APP_SITE_URL;

    // Generate links only if the current URL matches the language pattern
    supportedHrefLanguages.forEach(lang => {
        const keyword = hreflangKeywords[lang];
        const pattern = `/${lang}/accommodation/${keyword}`;

        if (currentUrl.includes(pattern)) {
            const [before, after] = currentUrl.split(pattern);

            // Generate hreflang tags for all supported languages
            supportedHrefLanguages.forEach(lang => {
                const keyword = hreflangKeywords[lang];
                const newUrl = `${baseUrl}${before}/${lang}/accommodation/${keyword}${after}`;
                createLinkTag('alternate', lang, newUrl);
            });

            // Add x-default hreflang tag pointing to Spanish ("es") as the default language
            const defaultUrl = `${baseUrl}${before}/es/accommodation/${hreflangKeywords['es']}${after}`;
            createLinkTag('alternate', 'x-default', defaultUrl);
        }
    });
}

export function addCanonicalTag() {
    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
    createLinkTag('canonical', null, canonicalUrl);
}

// Main function to get related city links based on the current location
export function getRelatedCitiesLinks(currentLocation) {
    
    const baseUrl = process.env.VUE_APP_SITE_URL;
    const currentLanguage = getCurrentLanguage();

    // Filter and select related cities
    const relatedCities = getRelatedCities(currentLocation);

    // Generate links for each related city
    return relatedCities.map(location => buildCityLink(location, currentLanguage, baseUrl));
}

// Main function to get related city links based on the current location
export function getOthersCitiesLinks() {
    
    const baseUrl = process.env.VUE_APP_SITE_URL;

    const browserLanguage = navigator.language || navigator.userLanguage;
    const currentSupportedLanguage = getClosestLanguage(browserLanguage);
    
    // Filter and select related cities
    const otherCities = getOthersCities();

    // Generate links for each related city
    return otherCities.map(location => buildCityLink(location, currentSupportedLanguage, baseUrl));
}

// Gets the current language from the URL
function getCurrentLanguage() {
    return window.location.pathname.split('/')[1]; // Example: 'es-ES'
}

// Get closest supported language based on the browser language
function getClosestLanguage(language) {
    // Check for an exact match
    if (supportedLanguages.includes(language)) {
        return language;
    }

    // Check for a close match based on the language prefix (e.g., "es" in "es-AR")
    const languagePrefix = language.split('-')[0];
    const closestMatch = supportedLanguages.find((lang) =>
        lang.startsWith(languagePrefix)
    );

    // Return the closest match or a default value
    return closestMatch || 'en'; // Default to 'en' if no match is found
}

// Filters and selects related cities in the same country with `search_query_enabled`
function getRelatedCities(currentLocation) {
    return locationsList
        .filter(location =>
            location.country === currentLocation.country &&
            location.search_query_enabled
        )
        .sort(() => 0.5 - Math.random()) // Randomly shuffle the list
        .slice(0, 3); // Limit to the first 3 cities
}

// Filters and selects other cities with `search_query_enabled`
function getOthersCities() {
    return locationsList
        .filter(location =>
            location.search_query_enabled &&
            location.home_search_query_enabled
        )
        .sort(() => 0.5 - Math.random()) // Randomly shuffle the list
        .slice(0, 5); // Limit to the first 3 cities
}

// Builds the link for each city with the necessary parameters
function buildCityLink(location, language, baseUrl) {
    const keyword = hreflangKeywords[language];
    const { city, neighborhood } = getCityAndNeighborhood(location.comuna);

    // Include `neighborhood` in the path only if it is not empty
    const path = neighborhood
        ? `/${language}/accommodation/${keyword}_${city}_${neighborhood}`
        : `/${language}/accommodation/${keyword}_${city}`;

    const url = `${baseUrl}${path}`;
    const name = location.comuna;
    const image = loadCityImage(location.image_filename);

    return { url, name, image };
}

// Extracts and formats city and neighborhood from `location.comuna`
function getCityAndNeighborhood(comuna) {
    let parts = comuna.toLowerCase()
        .split(', ')
        .slice(-2)
        .map(part => part.trim().replace(/[ ]+/g, '-'));

    // If there is only one part, use it as `city` and leave `neighborhood` empty
    const city = parts[0];
    const neighborhood = parts[1] || ''; // Use an empty string if `neighborhood` is absent

    return { city, neighborhood };
}

// Dynamically loads the city image
export function loadCityImage(filename) {
    return require(`@/new-assets/locations_images/${filename}.jpg`);
}

export function setDescriptionHeader(description) {
    // Create or replace meta description
    let descriptionTag = document.querySelector('meta[name="description"]');

    if (!descriptionTag) {
        // If the meta description tag does not exist, create it
        descriptionTag = document.createElement('meta');
        descriptionTag.setAttribute('name', 'description');
        document.head.appendChild(descriptionTag);
    }

    // Set the content of the meta description tag
    descriptionTag.setAttribute('content', description);
}

