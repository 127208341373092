<template>
    <div class="other-locations container">
        
<!--        <div class="title">{{$t('other-locations.title')}}</div>-->

        <div class="title-wrapper">
            <div class="title">{{$t('other-locations.title')}}</div>
            <!-- <button class="arrow-button desktop-only" @click="scrollRight">
                <i class="fa fa-chevron-right" />
            </button> -->
        </div>
        
        <ul ref="scrollContainer" class="d-flex overflow-auto">
            <li v-for="(query, index) in relatedQueries" :key="index" class="query-item">
                <a :href="query.url" target="_self" class="query-link">
                    <img :src="query.image" :alt="query.name" class="query-image" />
                    <div class="query-name-wrapper">
                        <span class="query-name">{{ query.name }}</span>
                    </div>

                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "OtherLocations",
    props: {
        relatedQueries: {
            type: Array,
            required: true
        },
    },
    methods: {
        scrollRight() {
            // const container = this.$refs.scrollContainer;
            // if (container) {
            //     container.scrollBy({ left: 200, behavior: 'smooth' });
            // }
        }
    }
};
</script>

<style lang="scss" scoped>
.other-locations {

    .title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0 0 0;
    }

    .title {
        padding: 0;
        font-size: 2.3rem;
        color: $h2;
    }

    // .arrow-button {
    //     width: 50px;
    //     height: 50px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background: rgba($hf-primary, 0.15);
    //     border: none;
    //     border-radius: 10px;
    //     cursor: pointer;
    // }

    ul {
        margin-top: 0px;
        display: flex;
        justify-content: left;
        padding: 6px;
        gap: 25px !important;
        list-style: none;
        overflow-x: auto;

        li {
            height:310px;
            flex: 0 0 380px;
            position: relative;
            border-radius: $border-radius-new;
            overflow: hidden;
            box-shadow: 0 0px 6px rgba($hf-primary, 1);
            transition: transform 0.3s ease;

            &:hover {
                transform: translateY(-5px); /* Hover animation */
            }

            .query-link {
                text-decoration: none;
                display: flex; /* Use flexbox */
                align-items: center; /* Vertically center content */
                justify-content: center; /* Horizontally center content */
                position: relative;
                height: 100%; /* Ensure the link fills the entire container */
                color: white;
                padding: 0 30px;
            }

            .query-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover; /* Ensure the image covers the entire space */
                z-index: 1;
                filter: sepia(70%) opacity(100%) saturate(100%) brightness(80%);
                transition: opacity 0.3s ease; /* Add transition for hover effect */
                padding: 7px;
                border: 1px solid $hf-primary;
                border-radius: $border-radius-new;
            }

            .query-name-wrapper {
                padding: 5px;
                display: flex;
                background-color: white;
                z-index: 1;
                border-radius: $border-radius-new;
            }

            .query-name {
                position: relative;
                z-index: 2;
                background: rgba(255, 255, 255, 0.8);
                font-size: 16px;
                color: #333;
                font-weight: bold;
                text-align: center;
                padding: 10px;
                border: 1px solid $hf-primary;
                border-radius: $border-radius-new;
            }
        }
    }

    /* Media Query for mobile view to stack images vertically */
    @media (max-width: $md) {

        .title {
            padding: 20px 0 0;
            font-size: 1.5rem;
            color: $h2;
        }
        
        ul {
            gap: 20px;
            height: auto;
            scroll-behavior: smooth;
            padding: 6px;
            margin: 5px 0;
        }

        li {
            height: 200px !important;
            flex: 0 0 250px !important;
        }
    }
}
</style>
