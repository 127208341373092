export const routes = {
    home: '/',
    rent: '/rent',
    about: '/about',
    blog: 'https://houseandflats.com/blog/',
    email: 'mailto:hello@houseandflats.com',
    terms: '/tyc',
    privacy: '/privacidad',
    cookies: '/cookies',
    faq: '/faq',
    investors: '/investment',
    contact: '/contacto',
};