<template>
    <div class="desktop-items desktop-only">

        <!-- Logo -->
        <router-link :to="'/'" class="logo-desktop">
            <img :src="assets.logoHorizontalSrc" alt="Logo"/>
        </router-link>

        <!-- Menu -->
        <div class="menu">
            <ul>
                <li>
                    <router-link :to="{ name: 'home' }" >{{ $t('main-menu.home') }}</router-link>
                </li>
                <li v-if="isLoggedIn">
                    <router-link :to="{ name: 'my-accommodations' }" >{{ $t('main-menu.my-profile') }}</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'about' }" >{{ $t('main-menu.about') }}</router-link>
                </li>
                <li>
                    <a href="https://houseandflats.com/blog/">Blog</a>
                </li>
                <li>
                    <router-link :to="{ name: 'conversation' }" class="new-btn btn--primary">{{ $t('main-menu.messages') }}</router-link>
                </li>
                <li v-if="!isLoggedIn">
                    <router-link :to="{ name: 'login' }" class="new-btn btn--sec-carbon">{{ $t('main-menu.sign-in') }}</router-link>
                </li>
                <li v-if="isLoggedIn">
                    <a @click="logout" class="new-btn btn--white">{{$t('dashboard-side-menu.sign-out')}}</a>
                </li>
                <li>
                    <CurrencySwitcher/>
                </li>
                <li>
                    <LanguageSwitcher/>
                </li>
            </ul>
        </div>

    </div>
</template>
<script>
import CurrencySwitcher from "@/components/Header/CurrencySwitcher.vue"
import LanguageSwitcher from "@/components/Header/LanguageSwitcher.vue"
import { mapGetters, mapActions } from "vuex";
import { assets } from "@/new-constants/assets";

export default {
    name: 'HeaderDesktop',
    components: {
        CurrencySwitcher, 
        LanguageSwitcher
    },
    data() {
        return {
            assets
        }
    },
    computed: {
        ...mapGetters(["isLoggedIn"]),
    },
    methods: {
        ...mapActions(["logout"]),
    }
}
</script>

<style lang="scss" scoped>
@import "@/new-assets/styles";

.desktop-items {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;

    .logo-desktop {
        img {
            height: 50px;
            padding: 10px 0;
        }
    }

    .menu {
        ul {
            display: flex;
            gap: 20px;
            list-style: none;
            margin: 0;
            padding: 0;
            align-items: center;

            li {
                a {
                    position: relative;
                    text-decoration: none;
                    color: $hf-sec-carbon;

                    // Animation
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: -6px;
                        width: 0;
                        height: 2px;
                        background-color: $hf-primary;
                        transition: width 0.3s ease;
                    }
                    // Animation
                    &:hover::after {
                        width: 100%;
                    }
                    
                    &:hover {
                        box-shadow: none !important;
                    }
                }
            }
        }
    }

    .switches {
        display: flex;
        align-items: center;
        gap: 15px;
    }
}

</style>