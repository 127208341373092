<template>
    <router-link :to="to" :class="['new-btn', `btn--${variant}`]">
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    name: 'ButtonLikeLink',
    props: {
        to: {
            type: Object,
            required: true
        },
        variant: {
            type: String,
            default: 'primary'
        }
    }
}
</script>