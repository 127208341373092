<template>
	<div class="container">
		<div v-if="!profileValidation" class="row">
			<div class="col-12">
				<h1>{{$t('dashboard.complete')}}</h1>
			</div>
			<div class="col-12 mt-5 pl-5">{{$t('dashboard.text')}}</div>
			<div class="col-12 mt-5 text-center text-sm-right">
				<a @click="handleClick" class="btn-complete mr-sm-5">{{$t('dashboard.button')}}</a>
			</div>
		</div>

		<Loader :message="message" v-if="loading" :error="error" />

		<div class="row" v-if="error">
			<div class="col-12 text-center">
				<button
					class="btn-complete mx-1"
					@click="goTo(1)"
					id="wizard-review-button"
				>{{$t('wizard.buttons.review')}}</button>
				<button
					class="btn-complete mx-1"
					@click="save"
					id="wizard-try-button"
				>{{$t('wizard.buttons.try')}}</button>
			</div>
			<div class="col-12 mt-5">
				<div
					class="col-12 text-center"
					style="color:#003D29"
				>{{$t('loader.support')}}</div>
				<div class="col-12 text-center">
					<a
						href="https://docs.google.com/forms/d/e/1FAIpQLSfjH2KMJu_EtaJHz571w69WCcvWnfNXmmTF-AzR8xbMVaHyog/viewform?vc=0&c=0&w=1"
						target="_blank"
					>
						<button class="btn-complete mx-1" id="wizard-bug-button">{{$t('wizard.buttons.bug')}}</button>
					</a>
				</div>
			</div>
		</div>

		<span v-if="initialLoad" v-show="!loading && profileValidation">
			<h1 v-if="$route.name === 'editar-alojamiento'">{{$t('dashboard-side-menu.edit-accommodation')}}</h1>
			<h1 v-else>{{$t('dashboard-side-menu.add-accommodation')}}</h1>
			<Steps :size="nSteps" :active="active" @step="goTo" />

			<Step1Wizard 
                v-show="active===1" 
                @next="nextStep" 
                :data="steps.step_01" 
                @set-room="setRoom" 
            />

			<Step2Wizard
				v-show="active===2"
				:place="accommodation.place"
				:modality="accommodation.modality"
                :type="accommodation"
				@prev="prevStep"
				@next="nextStep"
				:data="steps.step_02"
			/>
            
			<Step3Wizard 
                v-show="active===3" 
                @prev="prevStep" 
                @next="nextStep" 
                :data="steps.step_03" 
                :externalReference="steps.accommodation ? steps.accommodation.external_reference : null"
                :accommodationData="accommodation"
            />

			<Step4Wizard 
                v-if="active===4" 
                @prev="prevStep" 
                @next="nextStep"
                :data="steps.step_04"
                :accommodationData="accommodation"
            />

			<Step5Wizard
				v-show="active===5"
				:place="accommodation.place"
				@prev="prevStep"
				@next="nextStep"
				@new-room="addRoom"
				@remove-room="removeRoom"
				:data="steps.step_05"
                :accommodationData="accommodation"
			/>

			<Step6Wizard 
                v-show="active===6" 
                @prev="prevStep"
                @next="nextStep" 
                :data="steps.step_06" 
                :accommodationData="accommodation"
            />

			<Step7Wizard 
                v-show="active===7" 
                @prev="prevStep" 
                @next="nextStep" 
                :data="steps.step_07"
                :accommodationData="accommodation" 
            />

			<Step8Wizard
				v-show="active===8"
				@prev="prevStep"
				@next="nextStep"
				@save="save"
				:data="steps.step_08"
				:place="accommodation"
			/>

			<Step9Wizard
                v-show="active===9" 
                @prev="prevStep" 
                @save="save" 
            />

		</span>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Steps from "@/components/Wizard/Steps";
import Step1Wizard from "@/components/Wizard/Step1Wizard";
import Step2Wizard from "@/components/Wizard/Step2Wizard";
import Step3Wizard from "@/components/Wizard/Step3Wizard";
import Step4Wizard from "@/components/Wizard/Step4Wizard";
import Step5Wizard from "@/components/Wizard/Step5Wizard";
import Step6Wizard from "@/components/Wizard/Step6Wizard";
import Step7Wizard from "@/components/Wizard/Step7Wizard";
import Step8Wizard from "@/components/Wizard/Step8Wizard";
import Step9Wizard from "@/components/Wizard/Step9Wizard";
import FooterWizard from "@/components/Wizard/FooterWizard";
import Loader from "./../../components/Loader/Loader";
import axios from "axios";
import MisAlojamientos from "@/pages/Dashboard/MisAlojamientos.vue";
import { saveRedirectUrl } from "@/Utils/Utils";

export default {
	name: "WizardAlojamiento",
	data() {
		return {
			nSteps: 9,
			active: 1,
			rooms: 0,
			loading: false,
			message: "",
			steps: {},
			error: false,
			profileValidation: true,
			initialLoad: false,
			accommodation: {
				place: {
					casa: false,
					departamento: false,
					habitacion: false,
					compartido: false,
					residencia: false,
					entero: false
				},
				modality: {
					diario: false,
					mensual: false,
					ambos: false
				},
				aprobado: 0,
                verificado: 0,
                tipo: 'casa',
                sobre_nosotros: '',
                conserje: 0,
                timbre: 0,
                bodega: 0,
                estacionamiento_privado: 0,
                estacionamiento_visita: 0,
                estacionamiento_bicicleta: 0,
                sala_estar: 0,
                sala_eventos: 0,
                jardines: 0,
                piscina: 0,
                gimnasio_interno: 0,
                desayuno: 0,
                refrigerador: 0,
                cocina: 0,
                horno: 0,
                microondas: 0,
                parrilla: 0,                    
                lavadora: 0,
                secadora: 0,
                plancha: 0,
                limpieza: 0,
                mascotas: 0,
                paradero_micro: 0,
                estacion_metro: 0,
                hospital: 0,
                supermercado: 0,
                banco: 0,
                zona_nocturna: 0,
                areas_verdes: 0,
                centro_comercial: 0,
                centro_civico: 0,
                gimnasio_externo: 0,
                rooms: [],
			}
		};
	},
	components: {
		Steps,
		Step1Wizard,
		Step2Wizard,
		Step3Wizard,
		Step4Wizard,
		Step5Wizard,
		Step6Wizard,
		Step7Wizard,
		Step8Wizard,
		Step9Wizard,
		FooterWizard,
		Loader
	},

	methods: {
		...mapActions([
			"createAccommodation",
			"updateAccommodation",
			"checkUserData"
		]),
		nextStep(payload) {
			if (this.active < this.nSteps) {
				window.scrollTo(0, 0);
                this.accommodation = { ...this.accommodation, ...payload };
                this.active++;
                // If the accommodation has 0 rooms, skip the step 5
                if(this.accommodation.rooms.length === 0 && this.active === 5) {
                    this.active++;
                }
				
			}
		},
		prevStep() {
			if (this.active > 1) {
                this.active--;
                // If the accommodation has 0 rooms, skip the step 5
                if(this.accommodation.rooms.length === 0 && this.active === 5) {
                    this.active--;
                }
			}
		},
		goTo(payload) {
			this.active = payload;
			this.error = false;
			this.loading = false;
		},
        addRoom() {
            this.rooms.push({ /* propiedades del objeto de la habitación */ });
        },
        setRoom(n) {
            if (n > this.accommodation.rooms.length) {
                while (this.accommodation.rooms.length < n) {
                    this.accommodation.rooms.push({ /* default properties of the room object */ });
                }
            } else if (n < this.accommodation.rooms.length) {
                this.accommodation.rooms.splice(n);
            }
            this.steps.step_05 = this.accommodation.rooms;
        },
        removeRoom() {
            if (this.rooms.length > 0) {
                this.rooms.pop();
            }
        },
		bug() {
			this.$router.push("/informar-error");
		},
		save(payload) {
			if (payload) {
				this.accommodation = { ...this.accommodation, ...payload };
			}
			let form = new FormData();
			Object.keys(this.accommodation).forEach(item => {
				switch (item) {
					case "fotos":
						Object.values(this.accommodation.fotos).forEach(file => {
                            if (file instanceof File) {
                                form.append('fotos[]', file);
                            } else {
                                // otherwise, it's a JSON object
                                form.append('fotos[]', JSON.stringify(file));
                            }
                        });
						break;
					case "pieza":
						form.append(
							item,
							JSON.stringify(this.accommodation[item])
						);
						break;
					default:
						form.append(item, this.accommodation[item]);
						break;
				}
			});
			form.append("id", this.$route.params.idalojamiento);

			this.message = this.$t("loader.new_accommodation");
			this.loading = true;
			this.error = false;
			window.scrollTo(0, 0);

			if (this.$route.params.hasOwnProperty("idalojamiento")) {
				this.updateAccommodation(form)
					.then(response => {
						if (this.$route.name !== 'my-accommodations') {
                            this.$router.push({ name: 'my-accommodations' });
                        }
					})
					.catch(error => {
						this.message = this.$t("loader.error");
						this.error = true;
					});
			} else {
				this.createAccommodation(form)
                    .then(response => {
                        if (this.$route.name !== 'my-accommodations') {
                            this.$router.push({ name: 'my-accommodations' });
                        }
                    })
					.catch(error => {
						this.message = this.$t("loader.error");
						this.error = true;
					});
			}
		},
        handleClick() {
            saveRedirectUrl(this.$route);
            this.$router.push({ name: 'editar-perfil' });
        }
	},
	beforeRouteLeave(to, from, next) {
		if (to.name == "registro-alojamiento") {
			next();
			window.location.reload();
		} else {
			next();
		}
	},

	created() {
		window.scrollTo(0, 0);
		this.checkUserData().then(response => {
			if (response) {
				if (this.$route.params.hasOwnProperty("idalojamiento")) {
					this.loading = true;
					axios
						.get(
							process.env.VUE_APP_API_URL +
								"accommodations/logged/" +
								this.$route.params.idalojamiento
						)
						.then(accommodationResponse => {
                            this.steps = accommodationResponse.data;
                            this.accommodation.rooms = this.steps.step_05;
							this.accommodation.verificado =	accommodationResponse.data.accommodation.verificado;
							this.accommodation.aprobado =	accommodationResponse.data.accommodation.aprobado;
                            this.setRoom(this.steps.step_05.length);
							this.loading = false;
							this.initialLoad = true;
						})
						.catch(error => {
							this.$router.push("/dashboard");
						});
				} else {
					this.initialLoad = true;
				}
			} else {
				this.profileValidation = false;
				this.loading = false;
			}
		});

		this.$watch(this.$t, () => {
			if (this.message && this.error) {
				this.message = this.$t("loader.error");
			}
			if (this.message && !this.error) {
				this.message = this.$t("loader.new_accommodation");
			}
		});
	}
};
</script>


<style lang="scss" scoped>
.container {
	h1 {
		color: $hf-sec-pine;
		font-size: 1.8em;
		//border-bottom: solid rgb(231, 231, 231) 0.5px;
		padding-bottom: 10px;
		margin-bottom: 20px;
		text-align: right;
	}

	.btn-complete {
		color: white;
		background-color: $hf-sec-pine;
		padding: 17px 20px;
		border-radius: 5px;
		font-weight: bold;
		text-decoration: none;

		&:active {
			padding: 15px 18px;
		}
	}
}
</style>
