<template>
    <div class="featured">

        <!-- featured__pictures -->
        <div class="featured__pictures">
            <router-link :to="{ name: 'detalle-alojamiento', params: { idalojamiento: accommodation.idalojamiento } }">
                <CarouselList 
                    :idAlojamiento="accommodation.idalojamiento" 
                    :externalReference="accommodation.external_reference" 
                    class="img" />
            </router-link>
        </div>

        <!-- Body -->
        <div class="featured__body">

            <!-- Price -->
            <div class="features__price">

                <span v-if="showMonthlyPrice">
                    {{ monthlyPrice | convertAndFormatPrice }} /{{ $t("accommodation.per-month") }}
                </span>
                <span v-if="showDailyPrice" class="px-1"> - </span> 
                <span v-if="showDailyPrice">
                    {{ dailyPrice | convertAndFormatPrice }} /{{ $t("accommodation.per-day") }}
                </span>

            </div>

            <!-- Favorites icon -->
<!--            <div class="featured__heart">-->
<!--                <img v-if="isLoggedIn && wish" @click="wishListFeatured(id)" src="@/assets/corazon.svg" />-->
<!--                <img v-else-if="isLoggedIn" @click="wishListFeatured(id)" src="@/assets/corazon-borde.svg" />-->
<!--                <img v-else  @click="warning()" src="@/assets/corazon-borde.svg" />-->
<!--            </div>-->

            <!-- Title -->
            <div class="featured__title">
                <router-link :to="'/alojamiento/'+ accommodation.idalojamiento"> 
                    {{ title | capitalFirst }} 
                </router-link>
            </div>

            <!-- Location -->
            <div class="featured__address">
                {{ accommodation.direccion.split(",").slice(-2).reverse().join(" | ") }}
            </div>
            
            <!-- Tags -->
            <div class="featured__tags">
                <span class="tag-m2">{{ accommodation.m2 }} m²</span>
                <span class="tag-rooms">{{ accommodation.rooms }} {{ $t("tags.rooms") }}</span>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import CarouselList from "@/components/Carousel/CarouselList";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import priceMixin from "@/mixins/priceMixin";
import accommodation from "@/store/modules/accommodation";
export default {
    name: "AccommodationItem",
    props: [
        "accommodation", 
    ],
    mixins: [ priceMixin ],
    
    data() {
        return {
            wish: false,
        };
    },
    
    components: {
        CarouselList
    },
    
    computed: {
        ...mapGetters(["isLoggedIn"]),
        ...mapState(["user"]),
        showMonthlyPrice() {
            return this.accommodation.modalidad_tiempo === 'mensual' || this.accommodation.modalidad_tiempo === 'ambos';
        },
        showDailyPrice() {
            return this.accommodation.modalidad_tiempo === 'diario' || this.accommodation.modalidad_tiempo === 'ambos';
        },
        title() {
            // The title is built with the categoría + tipo - alojamientoid
            return this.$t('accommodation.title.' + this.accommodation.tipo + '-' + this.accommodation.categoria)
        }
    },
    
    methods: {
        
        wishListFeatured(id) {
            if (this.isLoggedIn) {
                let alojamientodata = {
                    alojamiento_idalojamiento: id
                };
                axios(
                    {
                        url: process.env.VUE_APP_API_URL + "wishList/change",
                        data: alojamientodata,
                        method: "POST"
                    },
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    }
                ).then(response => {
                    this.wish = response.data.estado;
                });
            } else {
                this.warning();
            }
        },
        
        warning() {
            if (this.$i18n.locale === "es") {
                alert(
                    "Debes iniciar sesión para agregar este alojamiento a favoritos"
                );
            } else {
                alert("You must login to add this accommodation to favorites");
            }
        },
        
    },
    
    created() {
        
        if (this.isLoggedIn) {
            axios
                .get(process.env.VUE_APP_API_URL + "wishList/checkWish/" + this.accommodation.idalojamiento)
                .then(response => { this.wish = response.data.estado; });
        }
        
    }
};
</script>

<style scoped lang="scss">

.featured {
    margin: 10px;
    padding: 10px;
}

.featured__body {
    justify-content: space-between;
    overflow: hidden;
}

.features__price {
    display: flex;
    color: $grey-50;
    font-weight: bold;
    padding: 15px 0 0px 0;
    font-size: 0.7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.featured__title {
    a {
        color: $grey-50;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.featured__address {
    color: $grey-25;
}

.featured__tags {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    font-size: 0.8rem;
    gap: 5px;
    
    .tag-m2, .tag-rooms {
        background-color: $grey-5;
        padding: 2px 10px;
        margin-right: 5px;
        border-radius: 30px;
    }
    
    .tag-m2 {
        color: $hf-primary;
    }
    
    .tag-rooms {
        color: $hf-office;
    }
}

.featured__heart {
    position: absolute;
    right: 20px;
    margin-top: 10px;
}

.featured__pictures {
    border-radius: $border-radius-new;
    .img {
        border-radius: 10px;
    }
}

</style>